import React from 'react';
import Comment from './Comment';
import Atropos from 'atropos/react';

function App() {
  const images = [
    "/home/resim1.jpeg",
    "/home/resim2.jpeg",
    "/home/resim3.jpeg",
    "/home/resim4.jpeg",
    "/home/resim5.jpeg",
    "/home/resim6.jpeg",
    "/home/resim7.jpeg",
    "/home/resim8.jpeg",
  ];

  return (
    <div className="container mx-auto px-5 py-10 lg:px-32 lg:py-24">
      <div className="text-4xl text-center mb-10 font-semibold">Bizimle İletişimde Olun!</div>
      <div className="-m-1 flex flex-wrap md:-m-2">
        {images.map((image, index) => (
          <div key={index} className="flex w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-2 md:p-3"> 
                <Atropos className="my-atropos">

            <img className="block mx-auto h-full w-full object-cover object-center rounded-lg shadow-md" src={image} alt={`Image ${index}`} /> 
            </Atropos>

          </div>
        ))}
      </div>
      <Comment />
    </div>
  );
}

export default App;
