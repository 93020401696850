import { useState, useEffect } from 'react';
import { Disclosure } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { FaGlobe } from 'react-icons/fa';
import { IoLogInOutline } from "react-icons/io5";

const initialNavigation = [
  { name: 'Ana Sayfa', href: '/', current: false },
  { name: 'Abonelik Fırsatları', href: '/abonelikfirsatlari', current: false },
  { name: 'Hakkımızda', href: '/bizi-taniyin', current: false },
  { name: 'Ev Okulu App’te Ne Var?', href: '/ev_okulu_appte_ne_var', current: false },
  { name: 'Ev Okulu App Ne İşe Yarar?', href: '/ev_okulu_ap_ne_ise_yarar', current: false },
  { name: 'İletişim', href: '/bize-ulasin', current: false },
  { name: 'Giriş Yap', href: 'https://www.evokuluappweb.com/', current: false, external: true },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Nav() {
  const [navigation, setNavigation] = useState(initialNavigation);

  useEffect(() => {
    const currentPath = window.location.pathname;
    const updatedNavigation = initialNavigation.map(item => ({
      ...item,
      current: item.href === currentPath,
    }));
    setNavigation(updatedNavigation);
  }, []);

  const handleItemClick = (index) => {
    const updatedNavigation = navigation.map((item, i) => ({
      ...item,
      current: i === index,
    }));
    setNavigation(updatedNavigation);
  };

  return (
    <Disclosure as="nav" className="bg-white py-2 fixed w-full z-50 top-0 shadow">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-screen-2xl px-4 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="flex items-center">
                <img
                  className="h-20 w-auto"
                  src="/logo.png"
                  alt="Ev Okulu Derneği"
                />
                <div className="hidden lg:flex lg:ml-4 lg:items-center">
                  <a href="https://play.google.com/store/apps/details?id=org.evokulu.app&pcampaignid=web_share" target="_blank" rel="noopener noreferrer">
                    <img
                      src="/google.png"
                      alt="Google Play"
                      className="h-12 w-auto"
                    />
                  </a>
                  <a href="https://apps.apple.com/tr/app/ev-okulu-app/id6478512351?l=tr" target="_blank" rel="noopener noreferrer" className="ml-4">
                    <img
                      src="/app.png"
                      alt="APP Store"
                      className="h-12 w-auto"
                    />
                  </a>
                </div>
              </div>

              <div className="flex items-center">
                <div className="hidden lg:block lg:ml-6">
                  <div className="flex space-x-4">
                    {navigation.map((item, index) => (
                      <a
                        key={item.name}
                        href={item.href}
                        onClick={() => handleItemClick(index)}
                        className={classNames(
                          item.current ? 'bg-indigo-600 text-white text-lg' : 'text-[#03A9F4] text-lg hover:text-indigo-800 hover:border-b-2 hover:border-indigo-800',
                          'rounded-md px-3 py-2 text-sm font-medium inline-flex items-center'
                        )}
                        aria-current={item.current ? 'page' : undefined}
                        target={item.external ? '_blank' : undefined}
                        rel={item.external ? 'noopener noreferrer' : undefined}
                      >

                        {item.name}
                        {item.name === 'Giriş Yap' && <IoLogInOutline className="ml-2 h-5 w-5 " />}
                      </a>
                    ))}
                  </div>
                </div>
                <div className="lg:hidden">
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-indigo-600 hover:text-indigo-800 hover:bg-indigo-50 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-800">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-8 w-8" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-8 w-8" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="lg:hidden bg-fuchsia-700">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {navigation.map((item, index) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  onClick={() => handleItemClick(index)}
                  className={classNames(
                    item.current ? 'bg-white text-black' : 'text-white hover:text-gray-200 hover:border-b-2 hover:border-white',
                    'w-full px-3 py-2 text-lg font-medium inline-flex items-center rounded-md hover:underline'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                  target={item.external ? '_blank' : undefined}
                  rel={item.external ? 'noopener noreferrer' : undefined}
                >
                  {item.name}
                  {item.name === 'Giriş Yap' && <FaGlobe className="ml-2 h-5 w-5" />}
                </Disclosure.Button>
              ))}
              <div className="flex justify-center space-x-4 mt-4">
                <a href="https://play.google.com/store/apps/details?id=org.evokulu.app&pcampaignid=web_share" target="_blank" rel="noopener noreferrer">
                  <img
                    src="/google.png"
                    alt="Google Play"
                    className="h-10 w-auto"
                  />
                </a>
                <a href="https://apps.apple.com/tr/app/ev-okulu-app/id6478512351?l=tr" target="_blank" rel="noopener noreferrer">
                  <img
                    src="/app.png"
                    alt="APP Store"
                    className="h-10 w-auto"
                  />
                </a>
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
