import React from 'react';
import { Box, Container, Typography, CardMedia } from '@mui/material';

const Features = () => {
  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} justifyContent="center" alignItems="center">
      <Box width={{ xs: '100%', md: '25%' }} p={3} order={{ xs: 2, md: 1 }}>
          <CardMedia
            component="img"
            image="/assets/inapp/shop2.png"
            alt="App Screenshot"
            sx={{ mx: 'auto', maxWidth: '100%', height: 'auto' }}
          />
        </Box>
        <Box width={{ xs: '100%', md: '50%' }} p={3} order={{ xs: 1, md: 2 }}>
          <Typography variant="h4" component="h2" fontWeight="bold" color="primary" gutterBottom sx={{ textAlign: 'center', color: 'purple' }}>
            Ev Okulu App ‘‘Shop’’ta neler var?
          </Typography>
          <Typography variant="body1" align="center" paragraph>
            Çocuğunuz, danışanlarınız veya öğrencileriniz için alanında uzman ve yetkin kişilerden profesyonel destek almak, zeka testi, oyun terapisi, danışmanlık gibi pek çok hizmeti uygun koşullarda temin etmek için Ev Okulu App ‘‘SHOP’’ bölümünden faydalanabilirsiniz.
          </Typography>
          <Typography variant="body1" align="center" paragraph>
            Çocuğunuz, danışanlarınız veya öğrencileriniz için güvenle temin edebileceğiniz eğitim materyalleri ve zeka oyunlarına da yine ‘‘SHOP’’ bölümünden ulaşabilirsiniz. Meslek uzmanı olarak uzmanlık alanınızla ilgili test materyallerine ve kitapları Ev Okulu App ‘‘Shop’’ bölümünden kolayca temin edebilirsiniz.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}

export default Features;
