import React from 'react';
import { FaPlayCircle } from 'react-icons/fa'; // Örnek ikon kütüphanesi kullanımı

const Features = () => {
  return (
    <div className="flex flex-wrap bg-gradient-to-r from-pink-100 to-yellow-100 rounded-lg border-0 shadow-xl overflow-hidden">
      <div className="w-full md:w-1/3 p-4 transform hover:scale-105 transition-transform duration-500">
        <iframe
          className="rounded-lg shadow-md"
          width="100%"
          height="315"
          src="https://www.youtube.com/embed/wSFaumuXLuw"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>

      <div className="w-full pl-8 md:w-2/3 p-4" style={{ fontFamily: "'Poppins', sans-serif" }}>
        <h2 className="text-xl font-bold mb-4 flex items-center text-purple-600">Ev Okulu App’te çocuklar için neler var?</h2>
        <p style={{ fontFamily: "'Poppins', sans-serif" }}>Çocukların bütüncül gelişimini uzmanlar tarafından hazırlanmış olan güvenilir içerikler eşliğinde desteklerken aynı zamanda da öğrenmeyi eğlenceli bir hâle getirmek ister misiniz? Ev Okulu App, çocukların bilişsel, dil, fiziksel-motor ve sosyal-duygusal gelişimini destekleyen eğitici aktiviteleri, oyunları, eğitim ve atölyeleri içermektedir. Çocuklar için özenle hazırlanmış içerikler hem ebeveynlerin hem de eğitimcilerin yararlanabileceği şekilde oluşturulmuştur.  </p>
      </div>
    </div>
  );
};

export default Features;
