import React from 'react';
import { Box, Container, Typography, CardMedia } from '@mui/material';

const Features = () => {
  return (
    <Container maxWidth="lg" sx={{ py: 8, backgroundColor: '#F5FAFD' }}>
      <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} justifyContent="center" alignItems="center">
      <Box width={{ xs: '100%', md: '25%' }} p={3} order={{ xs: 2, md: 1 }}>
          <CardMedia
            component="img"
            image="/assets/inapp/sizden.png"
            alt="App Screenshot"
            sx={{ mx: 'auto', maxWidth: '100%', height: 'auto' }}
          />
        </Box>
        <Box width={{ xs: '100%', md: '50%' }} p={3} order={{ xs: 1, md: 2 }}>
          <Typography variant="h4" component="h2" fontWeight="bold" color="primary" gutterBottom sx={{ textAlign: 'center', color: 'purple' }}>
            Ev Okulu App ‘‘Sizden Gelenler’’de ne var?
          </Typography>
          <Typography variant="body1" align="center" paragraph>
            Güzelliklerin paylaşıldıkça çoğaldığına inanıyoruz. ‘‘Başka bir ailenin veya eğitimcinin yaptığı faydalı etkinlikten ben de haberdar olmak istiyorum ve o etkinlikten esinlenmek istiyorum.’’ diyorsanız Ev Okulu App ‘‘Sizden Gelenler’’ tam size göre.
          </Typography>
          <Typography variant="body1" align="center" paragraph>
            Hem gerçekleştirdiğiniz ve faydalı olduğunu gördüğünüz çalışmalarınızı başkalarının istifadesine sunabilirsiniz hem de başka insanların yararlı paylaşımlarından ilham alabilirsiniz.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}

export default Features;
