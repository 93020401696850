import React from 'react';
import { Link } from 'react-router-dom';
import { FaBook, FaArrowAltCircleRight } from 'react-icons/fa';
import Atropos from 'atropos/react';  

const bgColors = ['#ffadad', '#ffd6a5', '#fdffb6', '#caffbf', '#9bf6ff', '#ffc6ff', '#a0c4ff'];

const EducationCard = ({ education }) => {
  const bgColorStyle = { backgroundColor: bgColors[Math.floor(Math.random() * bgColors.length)] };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  return (
    
    <div style={bgColorStyle} className="max-w-sm p-6 border border-gray-200 rounded-lg shadow transition-transform ease-in-out hover:scale-105">
                        <Atropos className="my-atropos">

          <img 
          src={education.image} 
          alt={education.title} 
          className="w-full object-cover rounded-lg" 
          style={{ maxHeight: '50vh' }} 
        />  
              </Atropos>

      <div className="rounded-lg">
        <div className="flex items-start justify-start mb-3 text-black">
          <FaBook className="text-2xl h-7 mt-8" />
        </div>
        <Link to={`/abonelikfirsatlari/cocuklara_yonelik/${education.id}`} className="block bg-white p-2 rounded-md shadow-sm hover:bg-gray-100">
          <h5 className="font-semibold tracking-tight text-black truncate">{education.title}</h5>
        </Link>
        <Link onClick={scrollToTop} to={`/abonelikfirsatlari/cocuklara_yonelik/${education.id}`} className="inline-flex text-blue-600 font-medium items-center hover:underline mt-2 bg-white p-2 rounded-md shadow-sm hover:bg-gray-100">
          Detaylar
          <FaArrowAltCircleRight className="w-6 h-6 ml-2.5" />
        </Link>
      </div>

    </div>
  );
};

export default EducationCard;
