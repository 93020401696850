import React from 'react'
import Banner from '../components/Home/Banner'
import HomeContent from '../components/Home/HomeContent'
import Comment from '../components/Home/Comment'
import Gallery from '../components/Home/Gallery'

const Home = () => {
  return (
    <div>
        <Banner />
        <HomeContent />
        <Gallery />
        {/* <Comment />  */}
    </div>
  )
}

export default Home