import React from 'react';
import { FaPlayCircle } from 'react-icons/fa'; 

const Features = () => {
    return (
        <div className="flex flex-wrap bg-gradient-to-r from-pink-100 to-yellow-100 rounded-lg border-0 shadow-xl overflow-hidden">
            <div className="w-full md:w-1/3 p-4 transform hover:scale-105 transition-transform duration-500">
                <iframe
                    className="rounded-lg shadow-md"
                    width="100%"
                    height="315"
                    src="https://www.youtube.com/embed/m_jlKUj_754"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
            </div>

            <div className="w-full pl-8 md:w-2/3 p-4" style={{ fontFamily: "'Poppins', sans-serif" }}>
                <h2 className="text-xl font-bold mb-4 flex items-center text-purple-600">Ev Okulu App’te çocuklar için hangi Yabancı dil eğitimleri var?</h2>
                <p style={{ fontFamily: "'Poppins', sans-serif" }}>Dil öğrenimi, çocuklarda iletişim kurma, düşünceyi geliştirme ve bilgi aktarma gibi özeliklerinin gelişimine katkı sağladığı gibi yeni bir dili öğrenmek bir çocuğun zihninin gelişmesinde ve sosyalleşmesinde de büyük bir rol oynamaktadır.
                    Ev Okulu App’te okul öncesi, ilkokul ve ortaokul düzeyindeki çocukların yabancı dil gelişimini destekleyecek ve eğitmenler tarafından çocukların gelişim düzeyine uygun şekilde anlatılan İngilizce, Yunanca, Korece, Fransızca, İspanyolca, Rusça ve Japonca dil eğitimleri bulunmaktadır.
                </p>
            </div>
        </div>
    );
};

export default Features;
