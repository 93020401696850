import React from 'react';
import { Box, Container, Grid, Typography, Card, CardContent, CardMedia } from '@mui/material';

const features = [
  { id: 1, image: '/resim1.png', title: 'DİNLEMEK', description: 'Derneğimize başvuran her bir bireyi etkili iletişimin kurallarına dikkat ederek dinleriz.' },
  { id: 2, image: '/resim2.png', title: 'REHBERLİK', description: 'Her bir ailenin farklı yönelimleri, beklentileri ve ihtiyaçları olduğundan hareketle kişiye özel rehberlik hizmeti sunuyoruz.' },
  { id: 3, image: '/resim3.png', title: 'İŞ BİRLİĞİ', description: 'Eğitim adına ben de varım diyen kişi ve kurumlarla iş birliği kuruyoruz' },
  { id: 4, image: '/resim4.png', title: 'KABUL', description: 'Kişilerin inanç, ideoloji, düşünce yapılarını ayırt etmeksizin herkese ve her kesime hizmet veriyoruz' },
];

const AboutFeatures2 = () => {
  return (
    <Container sx={{ py: 8, backgroundColor: '#F5FAFD' }} maxWidth="lg">
      <Typography
        variant="h4"
        component="h2"
        fontWeight="bold"
        color="primary"
        align="center"
        gutterBottom
        sx={{ color: '#A983E5', py: 4 }}
      >
        DEĞERLERİMİZ
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {features.map((feature) => (
          <Grid item key={feature.id} xs={12} sm={6} md={6}>
            <Card sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, alignItems: 'center', textAlign: { xs: 'center', sm: 'left' }, p: 2 }}>
              <CardMedia
                component="img"
                image={feature.image}
                alt={`Resim ${feature.id}`}
                sx={{ width: 128, height: 128, m: 2 }}
              />
              <CardContent>
                <Typography variant="h6" component="h3" fontWeight="bold" sx={{ color: '#F79007', mb: 2 }}>
                  {feature.title}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {feature.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default AboutFeatures2;
