import React from 'react';
import { Box, Container, Typography, CardMedia, List, ListItem, ListItemText } from '@mui/material';

const Features = () => {
  return (
    <Container maxWidth="lg" sx={{ py: 8, backgroundColor: '#F5FAFD' }}>
      <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} justifyContent="center" alignItems="center">
      <Box width={{ xs: '100%', md: '25%' }} p={3} order={{ xs: 2, md: 1 }}>
          <CardMedia
            component="img"
            image="/assets/inapp/shop.png"
            alt="App Screenshot"
            sx={{ mx: 'auto', maxWidth: '100%', height: 'auto' }}
          />
        </Box>
        <Box width={{ xs: '100%', md: '50%' }} p={3} order={{ xs: 1, md: 2 }}>
          <Typography variant="h4" component="h2" fontWeight="bold" color="primary" gutterBottom sx={{ textAlign: 'center', color: 'purple' }}>
            Ev Okulu App ‘‘Etkinlikler’’de neler var?
          </Typography>
          <List sx={{ listStyleType: 'disc', pl: 4, mb: 4 }}>
            <ListItem sx={{ display: 'list-item', pl: 0 }}>
              <ListItemText primary="Çocuğunuzla veya öğrencilerinizle yapabileceğiniz binlerce ‘‘Eğitici Aktivite’’yi," />
            </ListItem>
            <ListItem sx={{ display: 'list-item', pl: 0 }}>
              <ListItemText primary="Hem ev ortamında hem de farklı ortamlarda oynanabilecek geliştirici oyunları, ‘‘Ev Oyunları’’ bölümünde," />
            </ListItem>
            <ListItem sx={{ display: 'list-item', pl: 0 }}>
              <ListItemText primary="Sıra dışı özgün sorularla çocuğunuz ve öğrencilerinizi gelişimine katkı sunmak için ‘‘SOR’’ bölümünde," />
            </ListItem>
            <ListItem sx={{ display: 'list-item', pl: 0 }}>
              <ListItemText primary="Çocuklarınız ve öğrencilerinizle gerçekleştirebileceğiniz faaliyetleri ‘‘YAP’’ bölümünde," />
            </ListItem>
            <ListItem sx={{ display: 'list-item', pl: 0 }}>
              <ListItemText primary="Çocuklarınız ve öğrenmesi ve araştırması gereken başlıkları ‘‘ARAŞTIR’’ bölümünde görebilirsiniz." />
            </ListItem>
          </List>
          <Typography variant="body2" align="center" sx={{ mt: 4 }}>
            Tüm bu başlıklar altında binlerce içeriğe erişebilir ve her gün yeni eklenen özgün içeriklerle sürekli güncel bilgiyle beslenebilirsiniz.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}

export default Features;
