import React from 'react'
import AboutContent from '../components/About/AboutContent'
import AboutFeatures from '../components/About/AboutFeatures'
import AboutFeatures2 from '../components/About/AboutFeatures2'
import AboutFeatures3 from '../components/About/AboutFeatures3'
import Banner from '../components/About/Banner'

const About = () => {
  return (
    <div>
        <Banner />
        <AboutContent />
        <AboutFeatures />
        <AboutFeatures2 />
        <AboutFeatures3 />
    </div>
  )
}

export default About