import React from 'react';

const Kvkk = () => {
    return (
        <div className="max-w-2xl mx-auto px-4 py-24">
            <h2 className="text-2xl font-bold mb-4 text-center">KİŞİSEL VERİLERİN KORUNMASINA İLİŞKİN AYDINLATMA METNİ</h2>
            <p> Eğitim Evde ve Ev Okulu Derneği İktisadi İşletmesi (“Ev Okulu Derneği” veya “Şirket”) olarak; kişisel verilerinizin gizliliği ve güvenliği, en önemli önceliklerimiz arasındadır. Bu kapsamda 6698 sayılı Kişisel Verilerin Korunması Kanunu’nun (“KVKK”) 10. maddesinden doğan aydınlatma yükümlülüğümüzü yerine getirmek amacıyla kişisel verileriniz hakkında sizleri bilgilendirmek isteriz.
                <br />
                <br />
                Kişisel veri, kimliğinizi belirli ya da belirlenebilir kılan her türlü bilgi anlamına gelmektedir. Ev Okulu Derneği tarafından işlenen kişisel verileriniz, bunların işlenme amaçları, aktarılabileceği alıcı grupları, toplanma yöntemi, hukuki sebebi ve söz konusu kişisel verilere ilişkin haklarınız aşağıda yer almaktadır.
            </p>
            <h3 className="text-xl font-bold mb-4">  A- ÜYELERİMİZ</h3>
            <h3>1- Ev Okulu Derneği tarafından hangi kişisel verileriniz işlenmektedir?</h3>
            <p>Ev Okulu Derneği APP platformunda yer alan üyelik koşullarını kabul ederek, web sitesinden veya mobil cihazlara kurulan uygulamadan giriş yapan ve platformda yer alan ürünleri takip eden ve/veya satın alan kişiler “APP Üyesi” olarak tanımlanmaktadır. Ev Okulu Derneği uygulama platformunda üye olmanız halinde aşağıdaki kişisel verileriniz işlenebilmektedir.</p>
            <ul className="list-disc list-inside">
                <li className="mb-2">Kimlik Bilgileriniz: adınız, soyadınız, cinsiyet ve yaş bilginiz, TCKN (yalnızca belli ürün gruplarını satın almanız veya eğitim içeriklerini talep etmeniz halinde mevzuat uyarınca işlenmektedir.)</li>
                <li className="mb-2">İletişim Bilgileriniz: cep telefonu numaranız, teslimat adresiniz, e-posta adresiniz,</li>
                <li className="mb-2">Müşteri İşlem Bilgileriniz: sipariş ve fatura bilgileriniz, teslimat işlem bilgileriniz, alışverişlerinizle ilgili gerçekleşen işlem geçmişi bilgisi, talep ve şikayet bilgileriniz, platformda yer alan içeriklere ilişkin yorum yapmanız ve/veya soru sormanız halinde yorum ve sorularınız içerisinde bizzat paylaştığınız bilgiler, Ev Okulu Derneği iletişim kanallarıyla yapılan konuşma içeriklerine yönelik bilgiler,
                </li>
                <li className="mb-2">
                    İşlem Güvenliği Bilgileriniz: IP adresi bilgisi, şifre ve parola bilgileri, çerez bilgileri,
                </li>
                <li className="mb-2">
                    Hukuki İşlem Bilgileriniz: yetkili kişi, kurum ve kuruluşlarla yazışmalardaki bilgiler, dava ve icra dosyalarındaki bilgiler, yasal bilgi talebi bilgileriniz,
                </li>
                <li className="mb-2">
                    Mesajlı Kayıt Bilgileriniz: çağrı merkeziyle yazılı görüşmeniz halinde metin kaydınız.
                </li>
            </ul>





            <h3>2-  Kişisel verilerinizin işlenmesinin amaçları ve toplama yöntemleri nelerdir?</h3>
            <p>Kimlik, iletişim ve müşteri işlem bilgileriniz; sözleşmesel ilişkimiz kapsamında; bizzat sizlerden, mobil uygulama ya da internet sitesinden elektronik ortamda otomatik olarak aşağıdaki amaçlarla toplanmakta ve işlenmektedir.</p>
            <ul className="list-disc list-inside">
                <li>
                    Sözleşmenin kurulması ve ifasına yönelik süreçlerin yürütülmesi,


                </li>
                <li>
                    Finans ve muhasebe süreçlerinin yürütülmesi ve denetimi,
                </li>
                <li>
                    Şirketin/derneğin iş faaliyetlerinin yürütülmesi ve denetimi,
                </li>
                <li>
                    Faturalandırma süreçlerinin yürütülmesi,

                </li>
                <li>
                    Lojistik, kargo faaliyetlerin yürütülmesi ve teslimat süreçlerinin takibinin yapılması,
                </li>
                <li>
                    İnternet sitesi/mobil uygulamalar üzerinden satın alım işlemlerinin gerçekleşmesi ve işlem yapanın kimlik bilgilerinin teyit edilmesi,


                </li>
                <li>
                    Açık rızanızın bulunması halinde, çeşitli pazarlama ve reklam faaliyetlerinde kullanabilmesi, tercihlerinize uygun ürünlerin tarafınıza sunulması başta olmak üzere, sizlere daha iyi bir alışveriş deneyiminin sağlanması ve memnuniyetinizin arttırılmasına yönelik faaliyetlerin yürütülmesi,


                </li>
                <li>
                    Müşteri memnuniyetini artırmak, platformdan alışveriş yapan müşterilerimizi tanıyabilmek ve müşteri çevresi analizinde kullanabilmek, Şirketimizin sunduğu ürün ve hizmetlerin geliştirilmesi ve iyileştirilmesine yönelik faaliyetlerin yürütülmesi,


                </li>
                <li>
                    Stratejik analiz çalışmalarının yürütülmesi,


                </li>
                <li>
                    İletişim faaliyetlerinin yürütülmesi,


                </li>
                <li>
                    İlgi alanlarınızı dikkate alarak ilgilenebileceğiniz içeriklerin sunulması,


                </li>
                <li>
                    Mesafeli satış sözleşmesi ve Tüketicinin Korunması Hakkında Kanun’un ilgili maddeleri tahtında platformumuz üzerinden akdedilen sözleşmelerin koşulları, güncel durumu ve güncellemeler ile ilgili iletişime geçilmesi ve gerekli bilgilendirmelerin yapılması,


                </li>
                <li>
                    Açık rızanızın bulunması halinde, Ev Okulu Derneği ve Ev Okulu Derneği İktisadi İşletmesi nezdindeki gelişmeler, fırsatlar ve yenilikler hakkında tarafınıza bilgi verilmesi,
                </li>
                <li>
                    İnternet sitesi ve/veya mobil uygulamalardan alışveriş yapan müşterilerimizin tanınması, müşteri çevresi analizinde kullanılmasına yönelik faaliyetlerin yürütülmesi ve bu kapsamda anlaşmalı kuruluşlar aracılığıyla elektronik ortamda ve/veya fiziki ortamda anket çalışmaları yapılması,


                </li>
                <li>
                    Ürün ve hizmetlerimize ilişkin talep, şikayet ve önerilerinizin değerlendirilmesi,
                </li>
                <li>
                    Ürünlere yönelik bilgi almanızın sağlanması,
                </li>
                <li>
                    Mal ve hizmet satış sonrası destek hizmetlerinin yürütülmesi,


                </li>
                <li>
                    Bilgi güvenliği süreçlerinin yürütülmesi,


                </li>
                <li>
                    Ev Okulu Derneği platformuna ilişkin olarak operasyonların güvenliğinin sağlanması,
                </li>
                <li>
                    Faaliyetlerin mevzuata uygun yürütülmesinin sağlanması,
                </li>
                <li>
                    Hukuk işlerinin takibi ve yürütülmesi,
                </li>
                <li>
                    Yetkili kişi, kamu kurum ve kuruluşlarına bilgi verilmesi.
                </li>
            </ul>






            <p>İşlem güvenliği bilgileriniz; sözleşmesel ilişkimiz kapsamında bizzat sizlerden, mobil uygulama ya da internet sitesinden elektronik ortamda otomatik olarak aşağıdaki amaçlarla toplanmakta ve işlenmektedir.</p>
            <ul className="list-disc list-inside">
                <li>
                    Sözleşmenin kurulması ve ifasına yönelik süreçlerin yürütülmesi,
                </li>
                <li>Şirketin/derneğin iş faaliyetlerinin yürütülmesi ve denetimi,
                </li>
                <li>İnternet sitesi/mobil uygulamalar üzerinden satın alım işlemlerinin gerçekleşmesi ve işlem yapanın kimlik bilgilerinin teyit edilmesi,
                </li>
                <li>Açık rızanızın bulunması halinde, çeşitli pazarlama ve reklam faaliyetlerinde kullanabilmesi, tercihlerinize uygun ürünlerin tarafınıza sunulması başta olmak üzere sizlere daha iyi bir alışveriş deneyiminin sağlanması ve memnuniyetinizin arttırılmasına yönelik faaliyetlerin yürütülmesi,


                </li>
                <li>Müşteri memnuniyetini artırmak, platformdan alışveriş yapan müşterilerimizi tanıyabilmek ve müşteri çevresi analizinde kullanabilmek, Şirketimizin sunduğu ürün ve hizmetlerin geliştirilmesi ve iyileştirilmesine yönelik faaliyetlerin yürütülmesi,
                </li>
                <li>
                    Bilgi güvenliği süreçlerinin yürütülmesi,


                </li>
                <li>
                    Ev Okulu Derneği platformuna ilişkin olarak operasyonların güvenliğinin sağlanması,


                </li>
                <li>
                    Faaliyetlerin mevzuata uygun yürütülmesinin sağlanması,
                </li>
                <li>
                    Yetkili kişi, kurum ve kuruluşlara bilgi verilmesi.


                </li>
            </ul>





            <p>Hukuki işlem bilgileriniz; sözleşmesel ilişkimiz kapsamında bizzat sizlerden, mobil uygulama, internet sitesinden elektronik ortamda otomatik olarak aşağıdaki amaçlarla toplanmakta ve işlenmektedir.</p>
            <ul className="list-disc list-inside">
                <li>
                    Sözleşmenin kurulması ve ifasına yönelik süreçlerin yürütülmesi,
                </li>
                <li>
                    Şirketin/derneğin iş faaliyetlerinin yürütülmesi ve denetimi,
                </li>
                <li>
                    Ürün ve hizmetlerimize ilişkin talep, şikayet ve önerilerinizin değerlendirilmesi,
                </li>
                <li>
                    Bilgi güvenliği süreçlerinin yürütülmesi,
                </li>
                <li>
                    Ev Okulu Derneği platformuna ilişkin olarak operasyonların güvenliğinin sağlanması,
                </li>
                <li>
                    Faaliyetlerin mevzuata uygun yürütülmesinin sağlanması,
                </li>
                <li>
                    Hukuk işlerinin takibi ve yürütülmesi,
                </li>
                <li>
                    Yetkili kişi, kurum ve kuruluşlara bilgi verilmesi.
                </li>
            </ul>





            <p>Yazılı kayıt bilgileriniz; çağrı merkeziyle iletişime geçmeniz halinde aşağıdaki amaçlarla toplanmakta ve işlenmektedir.</p>
            <ul className="list-disc list-inside">
                <li>
                    Sözleşmenin kurulması ve ifasına yönelik süreçlerin yürütülmesi,
                </li>
                <li>
                    İletişim faaliyetlerinin yürütülmesi,
                </li>
                <li>
                    Kampanyaların yürütülmesi, tercihlerinize uygun ürünlerin tarafınıza sunulması başta olmak üzere sizlere daha iyi bir alışveriş deneyiminin sağlanması ve memnuniyetinizin arttırılmasına yönelik faaliyetlerin yürütülmesi,
                </li>
                <li>
                    Şirketin/derneğin sunduğu ürün ve hizmetlerin geliştirilmesi ve iyileştirilmesine yönelik faaliyetlerin yürütülmesi,
                </li>
                <li>
                    İlgi alanlarınızı dikkate alarak ilgilenebileceğiniz içeriklerin sunulması,
                </li>
                <li>
                    Mesafeli satış sözleşmesi ve Tüketicinin Korunması Hakkında Kanun’un ilgili maddeleri tahtında platformumuz üzerinden akdedilen sözleşmelerin koşulları, güncel durumu ve güncellemeler ile ilgili iletişime geçilmesi ve gerekli bilgilendirmelerin yapılması,
                </li>
                <li>
                    Açık rızanızın bulunması halinde, Ev Okulu Derneği ve şirketinin nezdindeki gelişmeler, fırsatlar ve yenilikler hakkında tarafınıza bilgi verilmesi,
                </li>
                <li>
                    İnternet sitesi ve/veya mobil uygulamalardan alışveriş yapan müşterilerimizin tanınması, müşteri çevresi analizinde kullanılmasına yönelik faaliyetlerin yürütülmesi ve bu kapsamda anlaşmalı kuruluşlar aracılığıyla elektronik ortamda ve/veya fiziki ortamda anket çalışmaları yapılması,
                </li>
                <li>
                    Ürün ve hizmetlerimize ilişkin talep, şikayet ve önerilerinizin değerlendirilmesi,
                </li>
                <li>
                    Ev Okulu Derneği platformuna ilişkin olarak operasyonların güvenliğinin sağlanması,
                </li>
                <li>
                    Faaliyetlerin mevzuata uygun yürütülmesinin sağlanması,
                </li>
                <li>
                    Hukuk işlerinin takibi ve yürütülmesi,
                </li>
                <li>
                    Yetkili kişi, kurum ve kuruluşlara bilgi verilmesi.
                </li>
            </ul>










            <h3>3-  Kişisel verilerinizin işlenmesinin hukuki sebebi nedir?</h3>
            <p>Ev Okulu Derneği platformu, “Ev Okulu Derneği ve şirketi tarafından hangi kişisel verileriniz işlenmektedir?” başlığında yer alan kişisel verilerinizi yukarıda yer alan amaçlarla KVKK’nın 5, 8. ve 9. maddelerinde belirtilen ve aşağıda yer verilen hukuka uygunluk sebeplerine dayanılarak işlenmektedir.</p>
            <ul className="list-disc list-inside">
                <li>
                    6563 Elektronik Ticaretin Düzenlenmesi Hakkında Kanun, 6102 Sayılı Türk Ticaret Kanunu, 5237 Sayılı Türk Ceza Kanunu ve 6502 sayılı Tüketicinin Korunması Hakkında Kanun başta olmak üzere Şirketimizin tabi olduğu mevzuatta açıkça öngörülmüş olması hukuki sebebine dayanarak; şirket platformunda yer alan operasyonların güvenliğinin sağlanması, bilgi güvenliği süreçlerinin yürütülmesi, faaliyetlerin mevzuata uygun yürütülmesinin sağlanmasına yönelik faaliyetler başta olmak üzere mevzuattan kaynaklanan yükümlülüklerimizin yerine getirilmesi,
                </li>
                <li>
                    Sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması kaydıyla, kişisel verilerinizin işlenmesinin gerekli olması hukuki sebebine dayanarak; mesafeli satış sözleşmesi gibi Tüketicinin Korunması Hakkında Kanun’un ilgili maddeleri tahtında platformumuz üzerinden akdedilen sözleşmelerin kurulması faaliyetlerinin yürütülmesi, satın alım işlemlerinizin gerçekleştirilmesine yönelik faaliyetlerin yürütülmesi, şirketin iş faaliyetlerinin yürütülmesi ve denetimi, teslimat süreçlerinin takibinin yapılması, ürün ve hizmetlerimize ilişkin talep, şikayet ve önerilerinizin değerlendirilmesi, finans ve muhasebe süreçlerinin yürütülmesi ve denetimi, iletişim faaliyetlerinin yürütülmesi,
                </li>
                <li>
                    Şirketimizin ve derneğin hukuki yükümlülüğünün yerine getirebilmesi için zorunlu olması hukuki sebebine dayanarak; Mesafeli Sözleşmeler Yönetmeliği ve Elektronik Ticarette Hizmet Sağlayıcı ve Aracı Hizmet Sağlayıcılar Hakkında Yönetmelik başta olmak üzere Şirketimizin tabii olduğu ikincil mevzuatta yer alan ve/veya yetkili merciiler tarafından yayınlanan karar, kılavuz ve rehberlerde belirtilen hukuki yükümlülüklerinin yerine getirilmesi, yetkili kişi, kurum ve kuruluşlara bilgi verilmesi, faaliyetlerin mevzuata uygun yürütülmesinin sağlanması, hukuk işlerinin takibi ve yürütülmesi, finans ve muhasebe işlerinin yürütülmesi,
                </li>
                <li>
                    Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin zorunlu olması hukuki sebebine dayanarak; hukuk ve dava işlerinin yürütülmesi,
                </li>
                <li>
                    Temel hak ve özgürlüklerine zarar vermemek kaydıyla şirketimizin meşru menfaatleri için veri işlenmesinin zorunlu olması hukuki sebebine dayanarak; şirketimizin sunduğu ürün ve hizmetlerin geliştirilmesi ve iyileştirilmesine yönelik faaliyetlerin yürütülmesi,
                </li>
                <li>
                    Açık rızanızın bulunması hukuki sebebine dayanarak; yurtdışına kişisel veri aktarımı yapılması.
                </li>
            </ul>



            <h3>4-  Ev Okulu Derneği ve şirketi kişisel verilerinizi kimlere hangi sebeplerle aktarıyor?</h3>
            <p>Şirketimiz / derneğimiz, kişisel verilerinizi “bilme gereği” ve “kullanma gereği” ilkelerine uygun olarak, gerekli veri minimizasyonunu sağlayarak ve gerekli teknik ve idari güvenlik tedbirlerini alarak işlemeye özen göstermektedir. İş faaliyetlerinin yürütülmesi veya denetimi, iş sürekliliğinin sağlanması, dijital altyapıların işletilmesi farklı paydaşlarla sürekli veri akışını zaruri kıldığı için işlediğimiz kişisel verileri belirli amaçlarla üçüncü kişilere aktarmak durumundayız. Ayrıca, sözleşmesel ve kanuni yükümlülüklerimizi tam ve gereği gibi yerine getirebilmemiz için kişisel verilerinizin doğru ve güncel olması çok önemlidir. Bunun için de muhtelif iş ortaklarıyla ve hizmet sağlayıcılarla çalışmak durumundayız.

                Kişisel verileriniz, pazaryeri operasyonun yürütülmesi, sipariş verdiğiniz ürünlerin teslimat süreçlerinin yürütülmesi, süreçlerinize yönelik yardım ve destek taleplerinizin yönetilmesi, deneyiminizin ve memnuniyetinizin arttırılmasına yönelik faaliyetlerin yürütülmesi, şirketimizin sunduğu ürün ve hizmetlerin geliştirilmesi ve iyileştirilmesine yönelik faaliyetlerin yürütülmesi, sizlere daha iyi bir alışveriş deneyiminin sağlanması ve memnuniyetinizin arttırılmasına yönelik faaliyetlerin yürütülmesi, iş sürekliliğinin sağlanması, bilgi güvenliğinin sağlanması, kanuni yükümlülüklerin yerine getirilmesi amaçları başta olmak üzere ve işbu aydınlatma metninin ikinci maddesinde belirtilen amaçlarla sınırlı olarak gerekli olduğu ölçüde:

                Şirketimizin tabi olduğu mevzuatta açıkça öngörülmüş olması ve hukuki yükümlülüğünün yerine getirebilmesi için zorunlu olması, sözleşmenin kurulması veya ifası için gerekli olması hukuki sebeplerine dayanarak;</p>
            <ul className="list-disc list-inside">
                <li>
                    Satın alım süreçlerinin yürütülmesi amacıyla ürünün satıcısı ve hizmet sağlayıcıyla,
                </li>
                <li>
                    Lojistik faaliyetlerin yürütülmesi ve teslimat süreçlerinin takibinin yapılması amacıyla sizin ve/veya ürünün adına teslim edileceği kişi bilgisi satın alınan ürünün satıcısıyla, kargo firmalarıyla ve özel kuryelerle,</li>
                <li>
                    Faturalandırma süreçlerinin yürütülmesi amacıyla iş ortaklarımız, satın alınan ürünün satıcısı, danışmanlarımız ve hizmet sağlayıcılarımızla, bankalarla, mali müşavirlerimizle,</li>
                <li>
                    Mal ve hizmet satış ve satış sonrası destek hizmetlerinin sunulması için çağrı merkezi hizmeti sunan iş ortakları ve diğer hizmet sağlayıcılarıyla,
                </li>
                <li>
                    Hizmetlerin kalite kontrol, şikâyet yönetimi ve risk analizi alanında hizmet sunan iş ortakları ve hizmet sağlayıcılarıyla,
                </li>
                <li>
                    Finansal ve muhasebe süreçlerinin yönetimi, risklerin tespiti, değerlendirilmesi, dolandırıcılıkların önlenmesi için ilgili iş ortaklarımız, danışmanlarımız ve hizmet sağlayıcılarımızla, bankalarla, mali müşavirlerimizle,
                </li>
                <li>
                    Müşteriye e-faturasının elektronik olarak gönderilmesi için e-fatura iş ortağıyla; fiziki sözleşme veya fatura teslimatı yapılabilmesi amacıyla kargo ve kurye firmalarıyla, özel entegratör, bağımsız denetim, gümrük, mali müşavir/muhasebe hizmeti sunan iş ortaklarımızla,
                </li>
                <li>
                    Vergisel yükümlülüklerin yerine getirilmesi için vergi daireleriyle, vergi denetimleri sırasında fatura ve tahsilat makbuzlarının T.C. Hazine ve Maliye Bakanlığı yetkilileriyle,
                </li>
                <li>
                    Bilişim altyapımızı sağlayan, işleten veya hizmet sunan iş ortaklarımızla ve hizmet sağlayıcılarımızla,
                </li>
                <li>
                    Risk yönetimi ve finansal raporlama süreçlerinin yürütülmesi alanında hizmet sunan iş ortaklarımızla,
                </li>
                <p>
                    Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin zorunlu olması, şirketimizin tabi olduğu mevzuatta açıkça öngörülmüş olması ve hukuki yükümlülüğünün yerine getirebilmesi için zorunlu olması hukuki sebeplerine dayanarak;
                </p>
                <li>
                    Hukuki yükümlülüklerin yerine getirilmesi kapsamında avukatlar, denetçiler, adli bilişim uzmanları, siber güvenlik danışmanları, vergi danışmanları ile danışmanlık ve hizmet aldığımız diğer üçüncü kişilerle ve iş ortaklarıyla,
                </li>
                <li>
                    Düzenleyici ve denetleyici kurumlar ile mahkeme ve icra müdürlükleri gibi yetkili kamu kurum ve kuruluşlarıyla,
                </li>
                <li>
                    Kişisel verilerinizi talep etmeye yetkili olan diğer kamu kurum veya kuruluşlarıyla, yurtiçi ve/veya yurtdışında bulunan iştiraklerimizle, tedarikçilerimizle, iş ortaklarımızla, anlaşmalı olduğumuz bankalarla ve ürün veya hizmet aldığımız üçüncü kişilerle paylaşılabilmektedir.
                </li>
            </ul>






            <h3>
                B- MİSAFİR ÜYELERİMİZ
            </h3>
            <p>
                1-  Ev Okulu Derneği platformu tarafından hangi kişisel verileriniz işlenmektedir?
            </p>
            <ul className="list-disc list-inside">
                <li>
                    Kimlik Bilgileriniz: adınız, soyadınız,


                </li>
                <li>
                    İletişim Bilgileriniz: cep telefonu numaranız, teslimat adresiniz, e-posta adresiniz,
                </li>
                <li>
                    Müşteri İşlem Bilgileriniz: sipariş ve bilgileri, fatura bilgileri, teslimat işlem bilgileri, alışverişlerinizle ilgili gerçekleşen işlem geçmişi bilgisi, talep ve şikayet bilgileri,
                </li>
                <li>
                    İşlem Güvenliği Bilgileriniz: IP adresi bilgileri, çerez bilgileri,
                </li>
                <li>
                    Hukuki İşlem Bilgileriniz: yetkili kişi, kurum ve kuruluşlarla yazışmalardaki bilgiler, dava ve icra dosyalarındaki bilgiler, yasal bilgi talebi bilgileriniz,
                </li>
                <li>
                    Yazılı Kayıt Bilgileriniz: çağrı merkeziyle görüşmeniz halinde metin kaydı veriniz.
                </li>
            </ul>










            <p>
                2-  Kişisel verilerinizin işlenmesinin amaçları ve toplama yöntemleri nelerdir?            </p>
            <p>
                Kimlik, iletişim ve müşteri işlem bilgileriniz; bizzat sizlerden, mobil uygulama ya da internet sitesinden elektronik ortamda otomatik olarak aşağıdaki amaçlarla toplanmakta ve işlenmektedir.
            </p>

            <ul className="list-disc list-inside">
                <li>
                    Sözleşmenin kurulması ve ifasına yönelik süreçlerin yürütülmesi,

                </li>
                <li>
                    Finans ve muhasebe süreçlerinin yürütülmesi ve denetimi,
                </li>
                <li>
                    Şirketin/ derneğin iş faaliyetlerinin yürütülmesi ve denetimi,
                </li>
                <li>
                    Lojistik faaliyetlerin yürütülmesi ve teslimat süreçlerinin takibinin yapılması,
                </li>
                <li>
                    İnternet sitesi/ mobil uygulamalar üzerinden satın alım işlemlerinin gerçekleşmesi ve işlem yapanın kimlik bilgilerinin teyit edilmesi,
                </li>
                <li>
                    Şirketin/derneğin sunduğu ürün ve hizmetlerin geliştirilmesi ve iyileştirilmesine yönelik faaliyetlerin yürütülmesi,
                </li>
                <li>
                    Stratejik analiz çalışmalarının yürütülmesi,
                </li>
                <li>
                    İletişim faaliyetlerinin yürütülmesi,
                </li>
                <li>
                    Mesafeli satış sözleşmesi ve Tüketicinin Korunması Hakkında Kanun’un ilgili maddeleri tahtında platformumuz üzerinden akdedilen sözleşmelerin koşulları, güncel durumu ve güncellemeler ile ilgili iletişime geçilmesi ve gerekli bilgilendirmelerin yapılması,üyelik sözleşmesinin kurulması,
                </li>
                <li>
                    Ürün ve hizmetlerimize ilişkin talep, şikayet ve önerilerinin değerlendirilmesi,


                </li>
                <li>
                    Mal ve hizmet satış sonrası destek hizmetlerinin yürütülmesi,


                </li>
                <li>
                    Bilgi güvenliği süreçlerinin yürütülmesi,


                </li>
                <li>
                    Ev Okulu Derneği platformuna ilişkin olarak operasyonların güvenliğinin sağlanması,


                </li>
                <li>
                    Faaliyetlerin mevzuata uygun yürütülmesinin sağlanması,


                </li>
                <li>
                    Hukuk işlerinin takibi ve yürütülmesi,


                </li>
                <li>
                    Yetkili kişi, kurum ve kuruluşlara bilgi verilmesi.


                </li>
                <p>
                    İşlem güvenliği bilgileriniz; bizzat sizlerden, mobil uygulama ya da internet sitesinden elektronik ortamda otomatik olarak aşağıdaki amaçlarla toplanmakta ve işlenmektedir.


                </p>
                <li>
                    Sözleşmenin kurulması ve ifasına yönelik süreçlerin yürütülmesi,


                </li>
                <li>
                    Şirketin/derneğin iş faaliyetlerinin yürütülmesi ve denetimi,


                </li>
                <li>
                    İnternet sitesi/ mobil uygulamalar üzerinden satın alım işlemlerinin gerçekleşmesi ve işlem yapanın kimlik bilgilerinin teyit edilmesi,


                </li>
                <li>
                    Şirketin/derneğin sunduğu ürün ve hizmetlerin geliştirilmesi ve iyileştirilmesine yönelik faaliyetlerin yürütülmesi,
                </li>
                <li>
                    Bilgi güvenliği süreçlerinin yürütülmesi,
                </li>
                <li>
                    Ev Okulu Derneği platformuna ilişkin olarak operasyonların güvenliğinin sağlanması,
                </li>
                <li>
                    Faaliyetlerin mevzuata uygun yürütülmesinin sağlanması,
                </li>
                <li>
                    kişi, kurum ve kuruluşlara bilgi verilmesi.
                </li>
                <p> Hukuki işlem bilgileriniz; bizzat sizlerden, mobil uygulama, internet sitesinden elektronik ortamda otomatik olarak aşağıdaki amaçlarla toplanmakta ve işlenmektedir.</p>
                <li>
                    Sözleşmenin kurulması ve ifasına yönelik süreçlerin yürütülmesi,


                </li>
                <li>
                    Şirketin/derneğin iş faaliyetlerinin yürütülmesi ve denetimi,


                </li>
                <li>
                    Şirketimizin sunduğu ürün ve hizmetlerin geliştirilmesi ve iyileştirilmesine yönelik faaliyetlerin yürütülmesi
                </li>
                <li>Ürün ve hizmetlerimize ilişkin talep, şikayet ve önerilerinizin değerlendirilmesi,
                </li>
                <li>Bilgi güvenliği süreçlerinin yürütülmesi,
                </li>
                <li>Ev Okulu Derneği platformuna ilişkin olarak operasyonların güvenliğinin sağlanması,
                </li>
                <li> Faaliyetlerin mevzuata uygun yürütülmesinin sağlanması,
                </li>
                <li>Hukuk işlerinin takibi ve yürütülmesi,
                </li>
                <li>Yetkili kişi, kurum ve kuruluşlara bilgi verilmesi.
                </li>
                <li>Yazılı kayıt bilgileriniz; çağrı merkeziyle iletişime geçmeniz halinde aşağıdaki amaçlarla toplanmakta ve işlenmektedir.
                </li>
                <li>Sözleşmenin kurulması ve ifasına yönelik süreçlerin yürütülmesi,
                </li>
                <li>
                    İletişim faaliyetlerinin yürütülmesi,


                </li>
                <li>
                    Şirketimizin sunduğu ürün ve hizmetlerin geliştirilmesi ve iyileştirilmesine yönelik faaliyetlerin yürütülmesi,


                </li>
                <li>
                    Mesafeli satış sözleşmesi ve Tüketicinin Korunması Hakkında Kanun’un ilgili maddeleri tahtında platformumuz üzerinden akdedilen sözleşmelerin koşulları, güncel durumu ve güncellemeler ile ilgili iletişime geçilmesi ve gerekli bilgilendirmelerin yapılması,


                </li>
                <li>
                    Ürün ve hizmetlerimize ilişkin talep, şikayet ve önerilerinizin değerlendirilmesi,


                </li>
                <li>
                    Ev Okulu Derneği platformuna ilişkin olarak operasyonların güvenliğinin sağlanması,


                </li>
                <li>
                    Faaliyetlerin mevzuata uygun yürütülmesinin sağlanması,


                </li>
                <li>
                    Hukuk işlerinin takibi ve yürütülmesi,


                </li>
                <li>
                    Yetkili kişi, kurum ve kuruluşlara bilgi verilmesi.


                </li>


            </ul>









            <h3> 3-  Kişisel verilerinizin işlenmesinin hukuki sebebi nedir ?   </h3>
            <p>Ev Okulu Derneği ve şirketi, “Ev Okulu Derneği tarafından hangi kişisel verileriniz işlenmektedir?” başlığında yer alan kişisel verilerinizi yukarıda yer alan amaçlarla KVKK’nın 5, 8. ve 9. maddelerinde belirtilen ve aşağıda yer verilen hukuka uygunluk sebeplerine dayanılarak işlenmektedir.</p>
            <ul className="list-disc list-inside">
                <li>
                    6563 Elektronik Ticaretin Düzenlenmesi Hakkında Kanun, 6102 Sayılı Türk Ticaret Kanunu, 5237 Sayılı Türk Ceza Kanunu ve 6502 sayılı Tüketicinin Korunması Hakkında Kanun başta olmak üzere Şirketimizin tabi olduğu mevzuatta açıkça öngörülmüş olması hukuki sebebine dayanarak; şirket platformunda yer alan operasyonların güvenliğinin sağlanması, bilgi güvenliği süreçlerinin yürütülmesi, faaliyetlerin mevzuata uygun yürütülmesinin sağlanmasına yönelik faaliyetler başta olmak üzere mevzuattan kaynaklanan yükümlülüklerimizin yerine getirilmesi,,

                </li>
                <li>Sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması kaydıyla, kişisel verilerinizin işlenmesinin gerekli olması hukuki sebebine dayanarak; mesafeli satış sözleşmesi gibi Tüketicinin Korunması Hakkında Kanun’un ilgili maddeleri tahtında platformumuz üzerinden akdedilen sözleşmelerin kurulması faaliyetlerinin yürütülmesi, satın alım işlemlerinizin gerçekleştirilmesine yönelik faaliyetlerin yürütülmesi, şirketin iş faaliyetlerinin yürütülmesi ve denetimi, teslimat süreçlerinin takibinin yapılması, ürün ve hizmetlerimize ilişkin talep, şikayet ve önerilerinizin değerlendirilmesi, finans ve muhasebe süreçlerinin yürütülmesi ve denetimi, iletişim faaliyetlerinin yürütülmesi,

                </li>
                <li>Şirketimizin/derneğimizin hukuki yükümlülüğünün yerine getirebilmesi için zorunlu olması hukuki sebebine dayanarak; Mesafeli Sözleşmeler Yönetmeliği ve Elektronik Ticarette Hizmet Sağlayıcı ve Aracı Hizmet Sağlayıcılar Hakkında Yönetmelik başta olmak üzere Şirketimizin tabii olduğu ikincil mevzuatta yer alan ve/veya yetkili merciiler tarafından yayınlanan karar, kılavuz ve rehberlerde belirtilen hukuki yükümlülüklerinin yerine getirilmesi, yetkili kişi, kurum ve kuruluşlara bilgi verilmesi, faaliyetlerin mevzuata uygun yürütülmesinin sağlanması, hukuk işlerinin takibi ve yürütülmesi, finans ve muhasebe işlerinin yürütülmesi,
                </li>
                <li>Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin zorunlu olması hukuki sebebine dayanarak; hukuk ve dava işlerinin yürütülmesi,

                </li>
                <li>Temel hak ve özgürlüklerine zarar vermemek kaydıyla şirketimizin meşru menfaatleri için veri işlenmesinin zorunlu olması hukuki sebebine dayanarak; şirketimizin sunduğu ürün ve hizmetlerin geliştirilmesi ve iyileştirilmesine yönelik faaliyetlerin yürütülmesi,
                </li>
                <li>
                    Açık rızanızın bulunması hukuki sebebine dayanarak; yurtdışına kişisel veri aktarımı yapılması.
                </li>

            </ul>











            <h3>     4-  Ev Okulu Derneği ve şirketi kişisel verilerinizi kimlere hangi sebeplerle aktarıyor?</h3>
            <p>
                Kurumumuz, kişisel verilerinizi “bilme gereği” ve “kullanma gereği” ilkelerine uygun olarak, gerekli veri minimizasyonunu sağlayarak ve gerekli teknik ve idari güvenlik tedbirlerini alarak işlemeye özen göstermektedir. İş faaliyetlerinin yürütülmesi veya denetimi, iş sürekliliğinin sağlanması, dijital altyapıların işletilmesi farklı paydaşlarla sürekli veri akışını zaruri kıldığı için işlediğimiz kişisel verileri belirli amaçlarla üçüncü kişilere aktarmak durumundayız. Ayrıca, sözleşmesel ve kanuni yükümlülüklerimizi tam ve gereği gibi yerine getirebilmemiz için kişisel verilerinizin doğru ve güncel olması çok önemlidir. Bunun için de muhtelif iş ortaklarıyla ve hizmet sağlayıcılarla çalışmak durumundayız.

                Kişisel verileriniz, pazaryeri operasyonun yürütülmesi, sipariş verdiğiniz ürünlerin teslimat süreçlerinin yürütülmesi, süreçlerinize yönelik yardım ve destek taleplerinizin yönetilmesi, deneyiminizin ve memnuniyetinizin arttırılmasına yönelik faaliyetlerin yürütülmesi, şirketimizin sunduğu ürün ve hizmetlerin geliştirilmesi ve iyileştirilmesine yönelik faaliyetlerin yürütülmesi, sizlere daha iyi bir alışveriş deneyiminin sağlanması ve memnuniyetinizin arttırılmasına yönelik faaliyetlerin yürütülmesi, iş sürekliliğinin sağlanması, bilgi güvenliğinin sağlanması, kanuni yükümlülüklerin yerine getirilmesi amaçları başta olmak üzere ve işbu aydınlatma metninin ikinci maddesinde belirtilen amaçlarla sınırlı olarak gerekli olduğu ölçüde:

                Kurumumuz tabi olduğu mevzuatta açıkça öngörülmüş olması ve hukuki yükümlülüğünün yerine getirebilmesi için zorunlu olması, sözleşmenin kurulması veya ifası için gerekli olması hukuki sebeplerine dayanarak;
            </p>
            <ul className="list-disc list-inside">
                <li>
                    Satın alım süreçlerinin yürütülmesi amacıyla ürünün satıcısı ve hizmet sağlayıcıyla,


                </li>
                <li>Lojistik faaliyetlerin yürütülmesi ve teslimat süreçlerinin takibinin yapılması amacıyla sizin ve/veya ürünün adına teslim edileceği kişi bilgisi satın alınan ürünün satıcısıyla, kargo firmalarıyla ve özel kuryelerle,


                </li>
                <li>Faturalandırma süreçlerinin yürütülmesi amacıyla iş ortaklarımız, satın alınan ürünün satıcısı, danışmanlarımız ve hizmet sağlayıcılarımızla, bankalarla, mali müşavirlerimizle,


                </li>
                <li>Mal ve hizmet satış ve satış sonrası destek hizmetlerinin sunulması için çağrı merkezi hizmeti sunan iş ortakları ve diğer hizmet sağlayıcılarıyla,



                </li>
                <li>Hizmetlerin kalite kontrol, şikâyet yönetimi ve risk analizi alanında hizmet sunan iş ortakları ve hizmet sağlayıcılarıyla,


                </li>
                <li>
                    Finansal ve muhasebe süreçlerinin yönetimi, risklerin tespiti, değerlendirilmesi, dolandırıcılıkların önlenmesi için ilgili iş ortaklarımız, danışmanlarımız ve hizmet sağlayıcılarımızla, bankalarla, mali müşavirlerimizle,
                </li>
                <li>
                    Müşteriye e-faturasının elektronik olarak gönderilmesi için e-fatura iş ortağıyla; fiziki sözleşme veya fatura teslimatı yapılabilmesi amacıyla kargo ve kurye firmalarıyla, özel entegratör, bağımsız denetim, gümrük, mali müşavir/muhasebe hizmeti sunan iş ortaklarımızla,

                </li>
                <li>Vergisel yükümlülüklerin yerine getirilmesi için vergi daireleriyle, vergi denetimleri sırasında fatura ve tahsilat makbuzlarının T.C. Hazine ve Maliye Bakanlığı yetkilileriyle,
                </li>
                <li>
                    Bilişim altyapımızı sağlayan, işleten veya hizmet sunan iş ortaklarımızla ve hizmet sağlayıcılarımızla,


                </li>
                <li>
                    Risk yönetimi ve finansal raporlama süreçlerinin yürütülmesi alanında hizmet sunan iş ortaklarımızla,


                </li>
                <p>
                    Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin zorunlu olması, şirketimizin tabi olduğu mevzuatta açıkça öngörülmüş olması ve hukuki yükümlülüğünün yerine getirebilmesi için zorunlu olması hukuki sebeplerine dayanarak;


                </p>
                <li>
                    Hukuki yükümlülüklerin yerine getirilmesi kapsamında avukatlar, denetçiler, adli bilişim uzmanları, siber güvenlik danışmanları, vergi danışmanları ile danışmanlık ve hizmet aldığımız diğer üçüncü kişilerle ve iş ortaklarıyla,


                </li>
                <li>Düzenleyici ve denetleyici kurumlar ile mahkeme ve icra müdürlükleri gibi yetkili kamu kurum ve kuruluşlarıyla,

                </li>
                <li>
                    Kişisel verilerinizi talep etmeye yetkili olan diğer kamu kurum veya kuruluşlarıyla, yurtiçi ve/veya yurtdışında bulunan iştiraklerimizle, tedarikçilerimizle, iş ortaklarımızla, anlaşmalı olduğumuz bankalarla ve ürün veya hizmet aldığımız üçüncü kişilerle paylaşılabilmektedir.



                </li>
                <h3>  EV OKULU DERNEĞİ VE ŞİRKETİ KİŞİSEL VERİLERİNİZİ NASIL KORUYOR?</h3>
                <p>
                    Ev Okulu Derneği ve şirketi ile paylaşılan kişisel veriler, Ev Okulu Derneği gözetimi ve kontrolü altındadır. Ev Okulu Derneği, yürürlükteki ilgili mevzuat hükümleri gereğince bilginin gizliliğinin ve bütünlüğünün korunması amacıyla gerekli organizasyonu kurmak ve teknik önlemleri almak ve uyarlamak konusunda veri sorumlusu sıfatıyla sorumluluğu üstlenmiştir. Bu konudaki yükümlülüğümüzün bilincinde olarak;
                </p>
                <ul className="list-disc list-inside">
                    <li>
                        Veri gizliliğini konu alan uluslararası ve ulusal teknik standartlara uygun surette periyodik aralıklarda sızma testleri yaptırılmaktadır.
                    </li>

                    <li>
                        Web sitesi, mobil site ve mobil uygulama aracılığıyla Ev Okulu Derneği’ne ilettiğiniz kişisel verileriniz SSL (Secure Sockets Layer) teknolojisi kullanılarak korunmaktadır.

                    </li>
                    <li>
                        Kişisel veri işleme faaliyetlerine ilişkin düzenli olarak risk analizleri yapılmakta ve risklerin azaltılması için aksiyonlar alınmaktadır.


                    </li>
                    <li>
                        Kişisel verilere yetkisiz erişimleri engellemek için erişim ve yetki kontrolleri uygulanmaktadır.


                    </li>
                    <li>
                        Bu kapsamda veri işleme politikalarımızı her zaman güncellediğimizi bilginize sunarız.


                    </li>
                    <p>
                        Bu Aydınlatma Metni’nin “Hak ve Talepleriniz İçin İletişim” bölümünde yer alan yöntemlerle Şirketimize başvurarak,


                    </p>
                    <li>
                        Kişisel verilerinizin işlenip işlenmediğini öğrenme
                    </li>
                    <li>
                        İşlenmişse buna ilişkin bilgi talep etme,


                    </li>
                    <li>
                        Kişisel verilerinizin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,


                    </li>
                    <li>
                        Yurt içinde veya yurt dışında aktarıldığı üçüncü kişileri bilme,


                    </li>
                    <li>
                        Kişisel verilerin eksik veya yanlış işlenmiş olması halinde bunların düzeltilmesini isteme,


                    </li>
                    <li>
                        KVKK’da öngörülen şartlar çerçevesinde kişisel verilerinizin silinmesini veya yok edilmesini isteme,


                    </li>
                    <li>
                        Yukarıda belirtilen düzeltme, silinme ve yok edilme şeklindeki haklarınız uyarınca yapılan işlemlerin, kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,


                    </li>
                    <li>
                        İşlenen kişisel verilerinizin münhasıran otomatik sistemler ile analiz edilmesi sureti ile aleyhinize bir sonucun ortaya çıkmasına itiraz etme,


                    </li>
                    <li>
                        Kişisel verilerinizin ilgili mevzuata aykırı olarak işlenmesi sebebiyle zarara uğramanız hâlinde zararınızın giderilmesini talep etme haklarına sahipsiniz.


                    </li>
                    <p>
                        HAK VE TALEPLERİNİZ İÇİN İLETİŞİM

                        Kişisel verilerinizle ilgili sorularınızı ve taleplerinizi, Veri Sorumlusuna Başvuru Usul ve Esasları hakkında Tebliğ’de belirtilen şartlara uygun düzenlenmiş dilekçeyle ya da “DSM Grup Danışmanlık İletişim Ve Satış Ticaret A.Ş. Başvuru Formu” ile aşağıdaki yöntemlerle iletebilirsiniz. Başvuru formuna buradan ulaşabilirsiniz.


                    </p>
                </ul>


            </ul>
            <div className="overflow-x-auto">
                <table className="table-auto w-full border-collapse border border-gray-300">
                    <thead>
                        <tr className="bg-gray-200">
                            <th className="px-4 py-2">Başvuru Yöntemi</th>
                            <th className="px-4 py-2">Başvurunun Yapılacağı Adres</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="border px-4 py-2">Şahsen Yazılı Başvuru</td>
                            <td className="border px-4 py-2">Esentepe Mahallesi, Kore Şehitleri Caddesi, No: 34, D:1, Şişli / İSTANBUL</td>
                        </tr>
                        <tr>
                            <td className="border px-4 py-2">Noter</td>
                            <td className="border px-4 py-2">Esentepe Mahallesi, Kore Şehitleri Caddesi, No: 34, D:1, Şişli / İSTANBUL</td>
                        </tr>
                        <tr>
                            <td className="border px-4 py-2">Güvenli Elektronik İmza veya Mobil İmza İle İmzalanarak Kayıtlı Elektronik Posta (KEP)</td>
                            <td className="border px-4 py-2">egitimevde@hs01.kep.tr<br />evokuluapp@gmail.com</td>
                        </tr>
                        <tr>
                            <td className="border px-4 py-2">Şirketimize daha önce bildirilen ve sistemde kayıtlı bulunan elektronik posta adresinizden</td>
                            <td className="border px-4 py-2">Esentepe Mahallesi, Kore Şehitleri Caddesi, No: 34, D:1, Şişli / İSTANBUL</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <span >*”Şahsen Yazılı Başvuru” yoluna başvuracak İlgili Kişilerin kimliğini tevsik edici belgeleri ibraz etmesi zorunludur. Bu bağlamda evokuluapp@gmail.com adresine yapacağınız şahsen yazılı başvurularda başvuru formuyla birlikte kimlik fotokopisinin yalnızca ön yüzünün (kan grubu ve din hanesi gözükmeyecek şekilde) ibraz edilmesini rica ederiz </span>
            <span>
            **Başvuru formunun tebligat zarfına veya e-postanın konu kısmına “Kişisel Verilerin Korunması Kanunu İlgili Kişi Talebi” yazılmasını rica ederiz.

Kişisel veri sahipleri olarak, haklarınıza ilişkin taleplerinizi, EV OKULU DERNEĞİ’ne iletmeniz durumunda EV OKULU DERNEĞİ ve EV OKULU DERNEĞİ İKTİSADİ İŞLETMESİ talebinizi en geç 30 (otuz) gün içinde ücretsiz olarak sonuçlandıracaktır. Ancak, başvurunuza verilecek yanıtın mevzuat uyarınca bir maliyet gerektirmesi halinde EV OKULU DERNEĞİ tarafından Kişisel Verileri Koruma Kurulu tarafından belirlenen tarifedeki ücret talep edilebilecektir.

AYDINLATMA METNİ HAKKINDA

Ev Okulu Derneği ve şirketi işbu Kişisel Verilerin Korunması Hakkında Açıklama metnini yürürlükteki mevzuatta yapılabilecek değişiklikler çerçevesinde her zaman güncelleme hakkını saklı tutar.
            </span>
        </div>
    );
};

export default Kvkk;
