import React from 'react';

const Features = () => {
    return (
        <div className="flex flex-wrap bg-gradient-to-r from-pink-100 to-yellow-100 rounded-lg border-0 shadow-xl overflow-hidden">
            <div className="w-full md:w-1/3 p-4 transform hover:scale-105 transition-transform duration-500">
                <iframe
                    className="rounded-lg shadow-md"
                    width="100%"
                    height="315"
                    src="https://www.youtube.com/embed/QOxpJv42HaM"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
            </div>

            <div className="w-full pl-8 md:w-2/3 p-4" style={{ fontFamily: "'Poppins', sans-serif" }}>
                <h2 className="text-xl font-bold mb-4 flex items-center text-purple-600">Öğretmenler, eğitimciler ve ruh sağlığı meslek grupları için Ev Okulu App ne işe yarar?
                </h2>
                <p style={{ fontFamily: "'Poppins', sans-serif" }}>Çalıştığınız alanda uzmanlığınıza katkı sunacak ve kişisel gelişiminize fayda sağlayacak pek çoğu E-devlet onaylı, mali değeri 100 bin TL’ye varan eğitimlere Ev Okulu App ile ücretsiz erişebilirsiniz. Ayrıca aplikasyon kullanıcısı olarak bazı eğitim programlarına, özel avantajlı koşullarla kayıt olabilirsiniz.

                    Öğrencileriniz ve danışanlarınız için bireysel veya sınıf içinde grup ile uygulanabilecek binlerce etkinliğe erişebilir, çalıştığınız kişilerin eğlenirken öğrenmelerini destekleyebileceğiniz oyunlara ve onları zenginleştirecek araştırma konularına dair içeriklere Ev Okulu Aplikasyon içinde erişebilirsiniz.

                    Velilerinize ve danışanlarınıza önerebileceğiniz veya sizlerin de gidebileceği güncel etkinlikleri ‘‘Nerde Ne Var?’’ bölümünde takip edebilirsiniz. Sınıf içinde ve dışında öğrencilerinizle yaptığınız çalışmaları ‘‘Sizden Gelenler’’ bölümünde paylaşabilir, diğer uzman ve kullanıcıların paylaşımlarından ilham alabilirsiniz. Canlı ödüllü bilgi yarışmaları ile eğlenirken kazanabileceğiniz yarışmalara ‘‘Bilgi Yarışması’’ bölümünden katılabilirsiniz. ‘‘Blog’’ bölümünde eğitim üzerine makalelere, sesli bloga, video röportajlara ve yüzlerce kitaba erişebilirsiniz.
                </p>
            </div>
        </div>
    );
};

export default Features;
