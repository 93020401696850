import React from 'react';
import { FaPlayCircle } from 'react-icons/fa'; // Örnek ikon kütüphanesi kullanımı

const Features = () => {
  return (
    <div className="flex flex-wrap bg-gradient-to-r from-pink-100 to-yellow-100 rounded-lg border-0 shadow-xl overflow-hidden">
      <div className="w-full md:w-1/3 p-4 transform hover:scale-105 transition-transform duration-500">
        <iframe
          className="rounded-lg shadow-md"
          width="100%"
          height="315"
          src="https://www.youtube.com/embed/vKt02-iFF7E"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>

      <div className="w-full pl-8 md:w-2/3 p-4" style={{ fontFamily: "'Poppins', sans-serif" }}>
        <h2 className="text-xl font-bold mb-4 flex items-center text-purple-600">Ev Okulu App çocuklara mı yetişkinlere mi hitap eder?</h2>
        <ul className="text-lg text-gray-800 list-disc">
          <li>Çocukları ile kaliteli vakit geçirmek isteyen ebeveynlere sunduğu etkinlik, eğitici aktivite ve içerikler ile ebeveynlik becerilerini geliştirmek için eğitimlere katılmak isteyen ebeveynlere,</li>
          <li>Öğrencilerinin aynı zamanda bilişsel becerilerini de desteklemek isteyen, eğitim programlarına katılarak kendisini geliştirmek isteyen öğretmenlere;</li>
          <li>Terapistlik becerilerini geliştirmek için eğitimler alan tüm ruh sağlığı meslek gruplarına ve öğrencilerine;</li>
          <li>Eğitimler ile kendini geliştirmek isteyen tüm yetişkinlere;</li>
          <li>Çocuklara özel çekilmiş atölye ve eğitimler ile çocuklara;</li>
        </ul>
        <p>dolayısıyla çok geniş bir kesime, eğitim alanında ilgili herkese yönelik hazırlanmıştır. Ev Okulu App, bir Süper App’tir. Yani Ev Okulu App ile ayrı ayrı app olarak çıkabilecek pek çok içeriği tek bir aplikasyonda sizler için birleştirdik.</p>
      </div>
    </div>
  );
};

export default Features;
