import React from 'react';

export const questions = [
  {
    question: "Ev Okulu App nedir?",
    answer: <div dangerouslySetInnerHTML={{ __html: "<p> <br><br><strong>Ev Okulu App, Eğitimin Süper APP’idir. </strong><br><br>Ev Okulu App; farklı ihtiyaçlara hizmet eden pek çok uygulamayı içinde barındıran, mali değeri 100 bin TL’ye varan sertifikalı eğitimleri ücretsiz takip edebileceğiniz, binlerce eğitim videosundan ve eğitici aktivitelerden oluşan, eğitimci ve ebeveynlerin istifadesine sunulmuş bir eğitim aplikasyonudur. <br><br>Ev Okulu App ile çocuğunuz, öğrencileriniz ve danışanlarınız ile gerçekleştirebileceğiniz etkinlikleri görebilir, eğitim programlarına katılabilir, ödüllü bilgi yarışmalarında kendinizi sınayabilir, gidilebilecek ve gezilebilecek eğlenceli ve eğitsel mekanları inceleyebilir, makalelere, sesli ve video bloglara, ücretsiz e-kitaplara erişebilir ve kişisel gelişiminize katkı sunabilirsiniz. </p>" }} />,
    embeddedVideo: "https://www.youtube.com/embed/v3SXCf80w7E",
  },

  {
    question:
      "Ev Okulu App çocuklara mı yetişkinlere mi hitap eder?",
    answer: <div dangerouslySetInnerHTML={{ __html: "<p> <strong>Ev Okulu App;</strong> <br><br> • Çocukları ile kaliteli vakit geçirmek isteyen ebeveynlere sunduğu etkinlik, eğitici aktivite ve içerikler ile ebeveynlik becerilerini geliştirmek için eğitimlere katılmak isteyen ebeveynlere, <br/>  • Öğrencilerinin aynı zamanda bilişsel becerilerini de desteklemek isteyen, eğitim programlarına katılarak kendisini geliştirmek isteyen öğretmenlere;  <br/>  • Terapistlik becerilerini geliştirmek için eğitimler alan tüm ruh sağlığı meslek gruplarına ve öğrencilerine; <br/>   • Eğitimler ile kendini geliştirmek isteyen tüm yetişkinlere, <br/>  • Çocuklara özel çekilmiş atölye ve eğitimler ile çocuklara;  <br/> <br/>  dolayısıyla çok geniş bir kesime, eğitim alanında ilgili herkese yönelik hazırlanmıştır. <br/> <br/>  Ev Okulu App, bir Süper App’tir. Yani Ev Okulu App ile ayrı ayrı app olarak çıkabilecek pek çok içeriği tek bir aplikasyonda sizler için birleştirdik.    </p>" }} />,
    embeddedVideo: "https://www.youtube.com/embed/vKt02-iFF7E",
  },
  {
    question:
      "Ebeveynler için Ev Okulu App ne işe yarar?",
    answer: <div dangerouslySetInnerHTML={{ __html: "<p> <strong>Ebeveynler için Ev Okulu App;</strong> <br><br> • Bebeklikten itibaren 15 yaşa kadar çocuğu olan aileler için binlerce eğitici aktiviteyi bulabilir, <br/> • Evde ailecek oynayabileceğiniz ev oyunlarını öğrenebilir, <br/> • Aile içinde birbirinize sorabileceğiniz ilginç soruları görebilir, <br/> • Çocuğunuzun öğrenmesi ve araştırması gereken başlıkları öğrenebilir, <br/> • Ebeveyn olarak katılabileceğiniz onlarca sertifikalı eğitim programına Ev Okulu App'ta olmanın avantajlarıyla dahil olabilir, <br/> • 'Nerde Ne Var?' bölümü ile ailecek gidebileceğiniz etkinlikleri görebilir, <br/> • 'Sizden Gelenler' bölümü ile Ev Okulu App kullanıcısı ailelerin yaptığı çalışmaları görebilir ve siz de diğer kullanıcıların faydalanması için çalışmalarınızı bu bölümde paylaşabilirsiniz, <br/> • Blog bölümünde ebeveynlik üzerine makalelere, sesli bloga, video röportajlara ve yüzlerce kitaba erişebilir, <br/> • Bilgi Yarışması bölümünde ailecek katılabileceğiniz ve ödüller de kazanabileceğiniz yarışmalara canlı olarak katılabilirsiniz. <br/><br/> Aile üyelerinizin tamamını kapsayacak tüm bu içerikler ve daha fazlasına Ev Okulu App ile tek tıkla ulaşabilirsiniz. </p>" }} />,
    embeddedVideo: "https://www.youtube.com/embed/Yj-Rg1Czokk",
  },
  {
    question:
      "Öğretmenler, eğitimciler ve ruh sağlığı meslek grupları için Ev Okulu App ne işe yarar?",
    answer: <div dangerouslySetInnerHTML={{ __html: "<p>Çalıştığınız alanda uzmanlığınıza katkı sunacak ve kişisel gelişiminize fayda sağlayacak pek çoğu E-devlet onaylı, mali değeri 100 bin TL’ye varan eğitimlere Ev Okulu App ile ücretsiz erişebilirsiniz. Ayrıca aplikasyon kullanıcısı olarak bazı eğitim programlarına, özel avantajlı koşullarla kayıt olabilirsiniz.<br><br>Öğrencileriniz ve danışanlarınız için bireysel veya sınıf içinde grup ile uygulanabilecek binlerce etkinliğe erişebilir, çalıştığınız kişilerin eğlenirken öğrenmelerini destekleyebileceğiniz oyunlara ve onları zenginleştirecek araştırma konularına dair içeriklere Ev Okulu Aplikasyon içinde erişebilirsiniz.<br><br>Velilerinize ve danışanlarınıza önerebileceğiniz veya sizlerin de gidebileceği güncel etkinlikleri ‘‘Nerde Ne Var?’’ bölümünde takip edebilirsiniz. Sınıf içinde ve dışında öğrencilerinizle yaptığınız çalışmaları ‘‘Sizden Gelenler’’ bölümünde paylaşabilir, diğer uzman ve kullanıcıların paylaşımlarından ilham alabilirsiniz. Canlı ödüllü bilgi yarışmaları ile eğlenirken kazanabileceğiniz yarışmalara ‘‘Bilgi Yarışması’’ bölümünden katılabilirsiniz. ‘‘Blog’’ bölümünde eğitim üzerine makalelere, sesli bloga, video röportajlara ve yüzlerce kitaba erişebilirsiniz.</p>" }} />,
    embeddedVideo: "https://www.youtube.com/embed/QOxpJv42HaM",
  },
  {
    question:
      "Ev Okulu App’te çocuklar için neler var ?",
    answer: <div dangerouslySetInnerHTML={{ __html: "" }} />,
    embeddedVideo: "https://www.youtube.com/embed/wSFaumuXLuw",
  },
  {
    question:
      "Ev Okulu App’te çocuklar için hangi Yabancı dil eğitimleri var ?",
    answer: <div dangerouslySetInnerHTML={{ __html: "" }} />,
    embeddedVideo: "https://www.youtube.com/embed/m_jlKUj_754",
  },
  {
    question:
      "Ev Okulu App’te çocuklar için hangi Yabancı dil eğitimleri var ?",
    answer: <div dangerouslySetInnerHTML={{ __html: "" }} />,
    embeddedVideo: "https://www.youtube.com/embed/ag5ifmhKSKY",
  },
  {
    question:
      "Neden Ev Okulu App Eğitimlerine Kayıt olmalısınız?",
    answer: <div dangerouslySetInnerHTML={{ __html: "" }} />,
    embeddedVideo: "https://www.youtube.com/embed/BIv3yEsUvQ4",
  },
  {
    question:
      "Ev Okulu App’in içinde nasıl sürprizler/hediyeler var?",
    answer: <div dangerouslySetInnerHTML={{ __html: "<p> • Ev Okulu App’in içinde tüm abonelerin katılabileceği ücretsiz eğitimler; <br/> • ‘Size Özel’ kısmında sizin için özelleştirilmiş eğitim hediyeleri; <br/> • App içerisindeki içerikleri takip ederek aldığınız gelişim alanı puanlarıyla; fiziksel zeka oyunu ve kitap hediyeleri bulunmaktadır.  </p>" }} />,
    embeddedVideo: "https://www.youtube.com/embed/06Y9CWF_MEE",
  },
  {
    question:
      "‘‘Profil’’imde neler var ?",
    answer: <div dangerouslySetInnerHTML={{ __html: "<p> • Tüm satın aldığınız içerik ve ürünleri görebileceğiniz ‘‘SATIN ALDIKLARIM’’ bölümü, <br/> • Tamamladığınız eğitimlerin sertifikalarını indirebileceğiniz veya görüntüleyebileceğiniz SERTİFİKALARIM bölümü <br/> • İçeriklerden kazandığınız puanları görüntüleyebileceğiniz GELİŞİM PUANLARI bölümü, <br/> • Kayıt olduğunuz eğitimleri takip edebileceğiniz EĞİTİMLERİM bölümü, <br/> • Göndermiş olduğunuz dosyaları takip edebileceğiniz DOSYALARIM bölümü, <br/> • Profilinize uygun size tanımlanan fırsatları görebileceğiniz SİZE ÖZEL bölümü, <br/> • Kişisel bilgilerinizi girebileceğiniz BİLGİLERİM bölümü; profilinizi oluşturmaktadır. </p>" }} />,
    embeddedVideo: "https://www.youtube.com/embed/dbWPG153a64E",
  },
  {
    question:
      "Gelişim alanı puanları ne işe yarar?",
    answer: <div dangerouslySetInnerHTML={{ __html: "<p>Ev Okulu App içerisinde yaptığınız her etkinlikten, katıldığınız her çalışmadan, okuduğunuz ve dinlediğiniz her blog içeriğinde puan kazanırsınız. Kazandığınız puanlarınız ‘‘Size Özel’’ bölümümüzde görüntüleyebileceğiniz size özel hediye ve sürpriz ödüller kazandırır.    </p>" }} />,
    embeddedVideo: "https://www.youtube.com/embed/ys6HFw9N8SA",
  },
  {
    question:
      "‘‘Size Özel’’ Nedir? Nasıl Kullanılır?",
    answer: <div dangerouslySetInnerHTML={{ __html: "<p>Profilinizde girdiğiniz bilgilerden hareketle size özel oluşturulacak fırsatları, ücretsiz hakları ve indirimleri ‘‘SİZE ÖZEL’’ bölümünde görebilirsiniz. <br/><br/> ‘‘Size Özel’’ bölümünde yeni fırsatları görmek istiyorsanız profil bilgileriniz tamamlamayı unutmayınız.</p>" }} />,
    embeddedVideo: "https://www.youtube.com/embed/_XmtCQs5V38",
  },
  {
    question:
      "Ev Okulu App ‘‘Etkinlikler’’de neler var?",
    answer: <div dangerouslySetInnerHTML={{ __html: "<p> • Çocuğunuzla veya öğrencilerinizle yapabileceğiniz binlerce ‘‘Eğitici Aktivite’’yi <br/> • Hem ev ortamında hem de farklı ortamlarda oynanabilecek geliştirici oyunları, ‘‘Ev Oyunları’’ bölümünde, <br/> • Sıra dışı özgün sorularla çocuğunuz ve öğrencilerinizi gelişimine katkı sunmak için ‘‘SOR’’ bölümünde, <br/> • Çocuklarınız ve öğrencilerinizle gerçekleştirebileceğiniz faaliyetleri ‘‘YAP’’ bölümünde, <br/> • Çocuklarınız ve öğrenmesi ve araştırması gereken başlıkları ‘‘ARAŞTIR’’ bölümünde görebilirsiniz.<br/> <br/> Tüm bu başlıklar altında binlerce içeriğe erişebilir ve her gün yeni eklenen özgün içeriklerle sürekli güncel bilgiyle beslenebilirsiniz.    </p>" }} />,
    embeddedVideo: "https://www.youtube.com/embed/ot7EBDUc_vc",
  },
  {
    question:
      "Ev Okulu App ‘‘Günün Reçetesi’’nde ne var?",
    answer: <div dangerouslySetInnerHTML={{ __html: "<p> Her gün önünüze sizi geliştirecek faydalı bilgiler otomatik olarak düşsün istiyorsanız Ev Okulu App’teki ‘‘Günün Reçetesi’’ tam size göre. <br/> <br/> Her gün ‘Günün Videosu’ndan, ‘Günün Sözü’ne, ‘Günün Deyimi’nden, ‘Günün Atasözü’ne’, ‘Günün Anismasyonu/Filmi’nden ‘Günün Kelimesi’ne ve ‘Günün Tarihi Olayı’ına kadar; ‘‘GÜNÜN REÇETESİ’’ elinizin altında.İster siz faydalanabilirsiniz isterseniz de sevdiklerinizle ‘‘Günün Reçetesi’’ni paylaşabilirsiniz.</p>" }} />,
    embeddedVideo: "https://www.youtube.com/embed/rJZNzQJ4Qf0",
  },
  {
    question:
      "Ev Okulu App ‘‘Takvim’’de ne var?",
    answer: <div dangerouslySetInnerHTML={{ __html: "<p> ‘‘Hangi gün, nerede, ne gibi etkinlikler var?’’ merak ediyorsanız tüm müze, atölye, tiyatro, seminer içerikleri; hepsi tek uygulamada elinizin altında. <br/ <br/> Takvim bölümünde günü seçerek o gün nerede, hangi lokasyonda, ne tür etkinlikler var; görebileceğiniz bilgileri Ev Okulu App ‘‘Takvim’’ bölümünde görebilirsiniz.</p>" }} />,
    embeddedVideo: "https://www.youtube.com/embed/1QmnMpLWAJM",
  },
  {
    question:
      "Ev Okulu App ‘‘Nerde Ne Var?’’da ne var?",
    answer: <div dangerouslySetInnerHTML={{ __html: "<p> ‘‘Bu hafta sonu nereye gideyim? Çocuğumu nereye götürsem? Kişisel gelişimine faydalı olabilecek ne gibi bir aktiviteye katılsam?’’ diyorsanız Ev Okulu App sizin için araştırdı. <br/> <br/> İster çocuklara ister yetişkinlere yönelik olsun bulunduğunuz bölgede ne gibi etkinlikler var, ücretli ücretsiz nelere katılabilirsiniz ‘‘NERDE NE VAR?’’ bölümünde görebilirsiniz.</p>" }} />,
    embeddedVideo: "https://www.youtube.com/embed/Md6dUEujOww",
  },
  {
    question:
      "Ev Okulu App ‘‘Bilgi Yarışması’’nda ne var?",
    answer: <div dangerouslySetInnerHTML={{ __html: "<p> Televizyonda gördüğünüz ve ‘‘Aslında ben de cevabı biliyorum ve katılsaydım belki ben de kazanırdım’’ dediğiniz yarışmalar oluyor mu? Ev Okulu App Bilgi Yarışması ile siz de artık bir bilgi yarışmacısı olabilirsiniz. Her hafta CANLI ÖDÜLLÜ Bilgi Yarışmalarında kendinizi sınayabilir, LAPTOP’tan tablete, Drone’dan oyun konsoluna; yüzlerce ödülden birini kazanma şansını elde edebilirsiniz. Belki ilk seferde olmasa da şansınızı sonraki yarışmalarda sürdürebilirsiniz. <br/> <br/> Haydi siz de Ev Okulu App Bilgi Yarışmasında yarışmacılar arasına katılın!</p>" }} />,
    embeddedVideo: "https://www.youtube.com/embed/0C9Q-W6bEFw",
  },
  {
    question:
      "Ev Okulu App ‘‘Blog’’da ne var?",
    answer: <div dangerouslySetInnerHTML={{ __html: "<p>Hangi bilgiyi, sağlıklı bir şekilde ve nerden edinebilirim diyor ve bu konuda zorlanıyor musunuz? Alanın uzmanları elimin altında olsun ve bilgi önüme aksın, istiyor musunuz? Ev Okulu App ‘‘BLOG’’ bölümü tam size göre. <br/> <br/> • Makaleler’ bölümünden yüzlerce makaleye, <br/> • Sesli blog bölümünden evde, arabada, iş yerinde; ister telefondan ister kulaklıkla ister bluetooth ile bulunduğunuz ortamda dinleyebileceğiniz yüzlerce eğitim içeriğine,    <br/> • Alandaki uzman isimlerle röportajların olduğu ‘Video Blog’ bölümüne,    <br/> • Ücretsiz indirebileceğiniz binlerce kitabın olduğu ‘Kaynak Hazinesi’ne    <br/> <br/>  Ev Okulu App ‘‘Blog’’ ile erişebilirsiniz. </p>" }} />,
    embeddedVideo: "https://www.youtube.com/embed/tnVywrsSr4U",
  },
  {
    question:
      "Ev Okulu App ‘‘Sizden Gelenler’’de ne var?",
    answer: <div dangerouslySetInnerHTML={{ __html: "<p> Güzelliklerin paylaşıldıkça çoğaldığına inanıyoruz. ‘‘Başka bir ailenin veya eğitimcinin yaptığı faydalı etkinlikten ben de haberdar olmak istiyorum ve o etkinlikten esinlenmek istiyorum.’’ diyorsanız Ev Okulu App ‘‘Sizden Gelenler’’ tam size göre. <br/> <br/> Hem gerçekleştirdiğiniz ve faydalı olduğunu gördüğünüz çalışmalarınızı başkalarının istifadesine sunabilirsiniz hem de başka insanların yararlı paylaşımlarından ilham alabilirsiniz.</p>" }} />,
    embeddedVideo: "https://www.youtube.com/embed/xUBbYWYDJZE",
  },
  {
    question:
      "Ev Okulu App ‘‘Eğitimler’’de ne var?",
    answer: <div dangerouslySetInnerHTML={{ __html: "<p> Eğitim programlarıyla kendinizi geliştirmek ve geçerli sertifika programlarıyla hem mesleki gelişiminize hem de ebeveynlik becerilerinize katkı sunmak mı istiyorsunuz? Sertifikalı eğitim programlarına katılmak istiyor ama bütçe ayırmakta zorlanıyor musunuz? Ev Okulu App ‘‘EĞİTİMLER’’ bölümü tam size göre.Ev Okulu App ile mali bedeli 100 bin TL’yi aşkın sertifikalı eğitimlere ÜCRETSİZ kaydolabilirsiniz. Yüksek maliyetli pek çok eğitim programlarına ise düşük maliyetlerle katılabilirsiniz. Eğitimlerinizi tamamlayıp, sınavlarınıza online olarak katılabilir ve pek çoğu e-devlet onaylı eğitim programlarından sertifikalarınızı elde edebilirsiniz.</p>" }} />,
    embeddedVideo: "https://www.youtube.com/embed/vKdj4_TN2DA",
  },
  {
    question:
      "Ev Okulu App ‘‘Shop’’ta neler var?",
    answer: <div dangerouslySetInnerHTML={{ __html: "<p>Çocuğunuz, danışanlarınız veya öğrencileriniz için alanında uzman ve yetkin kişilerden profesyonel destek almak, zeka testi, oyun terapisi, danışmanlık gibi pek çok hizmeti uygun koşullarda temin etmek için Ev Okulu App ‘‘SHOP’’ bölümünden faydalanabilirsiniz.<br/> <br/>Çocuğunuz, danışanlarınız veya öğrencileriniz için güvenle temin edebileceğiniz eğitim materyalleri ve zeka oyunlarına da yine ‘‘SHOP’’ bölümünden ulaşabilirsiniz. Meslek uzmanı olarak uzmanlık alanınızla ilgili test materyallerine ve kitapları Ev Okulu App ‘‘Shop’’ bölümünden kolayca temin edebilirsiniz. </p>" }} />,
    embeddedVideo: "https://www.youtube.com/embed/wrNoBO8ymaw",
  },
  {
    question:
      "Ev Okulu App ile ücretsiz eğitimlere nasıl kaydolabilirim?",
    answer: <div dangerouslySetInnerHTML={{ __html: "<p>Ev Okulu App’e abonelik oluşturarak tüm ücretsiz eğitimlerimize katılabilirsiniz.</p>" }} />,
    embeddedVideo: "https://www.youtube.com/embed/oDRjW9H4iLk",
  },
  {
    question:
      "Sms kodu almıyorum ne yapmalıyım?",
    answer: <div dangerouslySetInnerHTML={{ __html: "<p>Öncelikle telefon numaranızın doğruluğunu ve internet bağlantınızı kontrol ediniz. Ardından tarafımıza evokuluapp@gmail.com mail adresimizden ya da 05374304302 telefon numaramız yaşadığınız durumu ekran görüntüsü ve bilgileriniz ile ulaştırabilirsiniz.<br/><br/>Eğer Ev Okulu Derneği’nden daha önce SMS almak istemediğinizi ifade ettiyseniz tarafınıza SMS ile kod gönderilemeyecektir.<br/> <a href='https://docs.google.com/forms/d/117KxH3Q_6G9vBqHH6WUYNKSciuGbQmzk0mr1Ztg4nIw/viewform?edit_requested=true' style='color:blue;'>Form İçin Tıklayınız</a></p>" }} />    ,
    embeddedVideo: "https://www.youtube.com/embed/_5EpdTkKx9A",
  },
  {
    question:
      "Türkiye de yaşamıyorum Ev Okulu App’e kayıt olabilir miyim ?",
    answer: <div dangerouslySetInnerHTML={{ __html: "<p>Ev Okulu App, Dünya’nın her yerinden indirilebilir özelliğe sahiptir. <br/> <br/> Telefon veya tabletinizden Ev Okulu App’i konumunuz fark etmeksizin indirerek tüm içeriklerimize ulaşabilirsiniz.</p>" }} />,
    embeddedVideo: "https://www.youtube.com/embed/ciJOhQG06lI",
  },
  {
    question:
      "Ev Okulu App ücretli mi?",
    answer: <div dangerouslySetInnerHTML={{ __html: "<p>Ev Okulu App’i ücretsiz bir şekilde indirerek 1000’lerce makale, çocuk kitabı, ebeveyn kitabı, blog yazılarına, bilgi yarışmalarına ve daha birçok içeriğe ücretsiz şekilde ulaşabilirsiniz. <br/> <br/> Her gün yeni içeriklerin eklendiği içerik havuzumuza, satın alma değeri 100.000 TL’ye yakın ücretsiz düzenlenen eğitimlerimize, bazı eğitici aktivitelere ve ödüllü bilgi yarışmalarına ulaşmak için abone ol butonu üzerinden sembolik katkı bedelleri ile abonelik oluşturabilirsiniz. Abone olduğunuzda uygulamanın kilitli bölümleri açılacak ve her gün sunulan yeni içeriklerin tümünü takip edebileceksiniz.</p>" }} />,
    embeddedVideo: "https://www.youtube.com/embed/VSlCsK5L5Po",
  },
  {
    question:
      "Parolamı unuttum ne yapmalıyım?",
    answer: <div dangerouslySetInnerHTML={{ __html: "<p>‘‘Parolamı Unuttum’’ butonuna tıklayarak e-posta veya telefon numaranıza gönderilecek kod ile parolanızı sıfırlayabilirsiniz.    </p>" }} />,
    embeddedVideo: "https://www.youtube.com/embed/cmUUeMTCCak",
  },
  {
    question:
      "Profil bilgisi nedir? Neden Önemlidir?",
    answer: <div dangerouslySetInnerHTML={{ __html: "<p>Ev Okulu App içerisinde binlerce eğitici içerik barındırmaktadır. Sizin için özelleştirilmiş içerikler, hediyeler ve eğitim programları sunabilmemiz için profil bilgilerinizin tam ve doğru olarak doldurulması önem taşımaktadır. <br/> <br/> Örneğin; çocuk sahibiyseniz çocuğunuzun yaşını/ çocuklarınızın yaşlarını girdiğinizde o yaş gruplarına özel içeriklerle karşılaşabilirsiniz. Meslek uzmanıysanız uzmanlık alanınızı girdiğinizde, uzmanlık alanınıza yönelik bildirim ve içerikler görüntülenebilecektir.</p>" }} />,
    embeddedVideo: "https://www.youtube.com/embed/tCx54hBpYms",
  },
  {
    question:
      "Meslek dosyamı yüklemem neden önemli?",
    answer: <div dangerouslySetInnerHTML={{ __html: "<p>Ev Okulu App’te herkesin katılabileceği eğitim programları olduğu gibi, sadece belirli meslek gruplarının katılabileceği eğitim programları da bulunmaktadır. Bu eğitim programlarına kayıt olabilmeniz için meslek belgenizi tek seferliğine yüklemeniz gerekmektedir.<br/> <br/>Herhangi bir meslek uzmanlığı gerektiren bir eğitime ÜCRETSİZ veya ücretli kaydolma düşünceniz yoksa meslek belgenizi yüklemenize gerek olmayacaktır.</p>" }} />,
    embeddedVideo: "https://www.youtube.com/embed/nPIrm-TZdv4",
  },
  {
    question:
      "Size nasıl ulaşabilirim?",
    answer: <div dangerouslySetInnerHTML={{ __html: "<p>Bize ulaşmak istediğinizde;<br/><br/>• evokuluapp@gmail.com mail adresimizden,<br/>• 05374304302 telefon numaramızdan,<br/>• Ev Okulu App/Destek bölümünden,<br/>• @evokuluapp sosyal medya hesaplarımızdan ulaşabilirsiniz.</p>" }} />    ,
    embeddedVideo: "https://www.youtube.com/embed/EIVWe23jKC0",
  },
  {
    question:
      "Tek bir üyelik ile birçok cihazdan giriş yapabilir miyim?",
    answer: <div dangerouslySetInnerHTML={{ __html: "<p>Tek bir üyelik ile tek bir cihazdan giriş yapabilirsiniz. Mevcut kullanıcı adı ve şifrenizle farklı bir cihazdan giriş yaptığınızda daha önce giriş yaptığınız cihazdaki oturumunuz kapatılır. En son giriş yaptığınız cihazdan oturumunuzu kullanmaya devam edebilirsiniz.    .</p>" }} />    ,
    embeddedVideo: "https://www.youtube.com/embed/mhMuN1rVDwE",
  },
  {
    question:
      "Ev Okulu App kaç yaşındaki çocuklar için içerikler sağlar?",
    answer: <div dangerouslySetInnerHTML={{ __html: "<p>Ev Okulu App; 0-3, 3-6, 7-10, 11-14 yaş gruplarındaki çocuklar için eğitici, zenginleştirici ve eğlendirici içerikler sağlamaktadır.</p>" }} />    ,
    embeddedVideo: "https://www.youtube.com/embed/hv9XBXrjJOw",
  },
  {
    question:
      "Yaş grubu farklı çocuklarım için birden çok üyelik mi oluşturmalıyım?",
    answer: <div dangerouslySetInnerHTML={{ __html: "<p>Sizin için özelleştirilmiş içerik havuzumuzdan faydalanabilmek için Profilinizi eksiksiz bir şekilde doldurmanız önem taşımaktadır. Profil bilgilerinizi doldururken birden fazla yaş seviyesi seçerek farklı yaş grubundaki çocuklarınız için tüm içeriklere erişebilirsiniz. Bu işlemi daha sonra gerçekleştirmek veya yaş seviyelerini değiştirmek isterseniz tekrar Ev Okulu App-Profilim-Bilgilerim kısmından seviyeler arasında geçiş yapabilir, seviye bilgisi değiştirebilirsiniz.</p>" }} />    ,
    embeddedVideo: "https://www.youtube.com/embed/PPj_sRedh3E",
  },
  {
    question:
      "Ev Okulu App’te kaç tür eğitim vardır?",
    answer: <div dangerouslySetInnerHTML={{ __html: "<p><strong>Ev Okulu App’te 3 tür eğitim bulunmaktadır.</strong><br/> <br/> </p><p><strong>Online eğitimler:</strong> <br/> Tamamen canlı yayınlar şeklinde düzenlenir. Eğitim tarihleri bölümünde yazan programa göre eğitim başlangıç gün ve saatlerinde katılım linkleri aktif olur. Canlı yayınların kayıt linkleri yayın tarihini takip eden hafta içerisinde kayıt linki olarak eğitim sayfasına eklenir. Kaçırılan dersler kayıttan izlenebilir.</p> <br/> <br/><p><strong>Offline eğitimler:</strong><br/> Her zaman izleyebileceğiniz, zamandan bağımsız eğitimlerdir. Eğitim içeriğinde bir önceki dersi izlenmeden bir sonraki derse geçilemez.</p><br/> <br/><p><strong>Online+Offline Eğitimler:</strong>  <br/>Eğitimin içerisinde canlı ve kayıttan dersler bulunmaktadır. Canlı dersleri belirtilen gün ve saatte canlı olarak izleyebilir, kaçırdığınız durumlarda da takip eden hafta içerisinde kayıtlı eğitim videosu yüklendiğinde kayıttan izleyebilirsiniz. Bu eğitim türünün içerisindeki offline eğitimleri ise sırasıyla olmak üzere istediğiniz zaman diliminde takip edebilirsiniz.</p>" }} />,
    embeddedVideo: "https://www.youtube.com/embed/7u64DCo2gW8",
  },
  {
    question:
      "Offline eğitimleri nasıl takip edebilirim?",
    answer: <div dangerouslySetInnerHTML={{ __html: "<p>Offline eğitimler, her zaman izleyebileceğiniz zamandan bağımsız eğitimlerdir. Eğitimin bir önceki dersi izlenmeden bir sonraki derse geçilemez. Eğitimin dersleri bitirilmeden sınav linki aktif olmayacaktır.</p>" }} />,
    embeddedVideo: "https://www.youtube.com/embed/sUv5IigIMIs",
  },
  {
    question:
      "Online+Offline eğitimleri nasıl takip edebilirim?",
    answer: <div dangerouslySetInnerHTML={{ __html: "<p>Eğitimin içerisinde canlı ve kayıttan dersler bulunmaktadır. Canlı dersleri belirtilen gün ve saatte canlı olarak izleyebilir, kaçırdığınız durumlarda da takip eden hafta içerisinde kayıtlı videosu yüklendiğinde kayıttan izleyebilirsiniz. Bu eğitim türünün içerisindeki offline eğitimleri ise sırasıyla istediğiniz zaman diliminde takip edebilirsiniz. Eğitimin dersleri bitirilmeden sınav linki aktif olmayacaktır.    </p>" }} />,
    embeddedVideo: "https://www.youtube.com/embed/xcq4YTI-UOk",
  },
  {
    question:
      "Sertifikamı nasıl oluştururum? Sertifikalarımı nerde görebilirim",
      answer: <div dangerouslySetInnerHTML={{ __html: "<p><strong>Sınavı olmayan eğitimler için:</strong> Eğitim videolarını izledikten sonra sertifikamı oluştur butonundan sertifikanızı oluşturabilirsiniz.<br><br><strong>Sınavı olan eğitimler için:</strong> Eğitim videolarını izledikten sonra sınav butonu aktif olacaktır. Sınava katılıp belirlenen başarı düzeyine eriştikten sonra sertifikamı oluştur butonu aktif olacak olup butona tıklayarak sertifikanızı oluşturabilirsiniz<br><br><strong>Sınavı ve ödevi olan eğitimler için:</strong> Eğitim videolarını izledikten sonra sınav butonu aktif olacaktır. Sınava katılıp belirlenen başarı düzeyine eriştikten sonra ödev gönder butonu aktif olacaktır. Ödevinizi gönderdikten sonra ödeviniz incelenecek olup doğru olduğu kabul edilmesi durumunda sertifikamı oluştur butonundan sertifikanızı oluşturabilirsiniz.<br><br>Eğitim ve gerekli işlemleri tamamladıktan sonra Profilim-Sertifikalarım kısmından tüm sertifikalarınıza erişebilirsiniz.</p>" }} />,
      embeddedVideo: "https://www.youtube.com/embed/UNBHMmyhDQg",
  },
  {
    question:
      "Eğitime kaydolamıyorum, neden olabilir?",
      answer: <div dangerouslySetInnerHTML={{ __html: "<p>Eğer eğitime ‘kayıt ol’ butonu yerine ‘abone ol’ butonunu görüyorsanız abonelik oluşturarak ilgili eğitime kaydolabilirsiniz.<br><br>Eğer ‘meslek bilginiz bu eğitime kaydolmak için uygun değildir’ ibaresini görüyorsanız farklı bir eğitim programına kaydolmanızı öneririz. Bu eğitim programı farklı meslek gruplarına hitap ediyor anlamı taşımaktadır. Eğitim sayfasında yer alan ‘‘kimler katılabilir’’ kısmını inceleyebilirsiniz.<br><br>Eğer ‘meslek belgeniz bulunmamaktadır’ uyarısı alıyorsanız Profilim-Bilgilerim kısmından meslek belgenizi yükleyiniz.</p>" }} />,
      embeddedVideo: "https://www.youtube.com/embed/lBaAtTe4j7A",
  },





];
