import React from 'react';
import { Box, Container, Typography, CardMedia } from '@mui/material';

const Features = () => {
  return (
    <Container maxWidth="lg" sx={{ py: 8, backgroundColor: '#F5FAFD' }}>
      <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} justifyContent="center" alignItems="center">
      <Box width={{ xs: '100%', md: '25%' }} p={3} order={{ xs: 2, md: 1 }}>
          <CardMedia
            component="img"
            image="/assets/inapp/recete.png"
            alt="App Screenshot"
            sx={{ mx: 'auto', maxWidth: '100%', height: 'auto' }}
          />
        </Box>
        <Box width={{ xs: '100%', md: '50%' }} p={3} order={{ xs: 1, md: 2 }}>
          <Typography variant="h4" component="h2" fontWeight="bold" color="primary" gutterBottom sx={{ textAlign: 'center', color: 'purple' }}>
            Ev Okulu App ‘‘Günün Reçetesi’’nde ne var?
          </Typography>
          <Typography variant="body1" align="center" paragraph>
            Her gün önünüze sizi geliştirecek faydalı bilgiler otomatik olarak düşsün istiyorsanız Ev Okulu App’teki ‘‘Günün Reçetesi’’ tam size göre. Her gün ‘Günün Videosu’ndan, ‘Günün Sözü’ne, ‘Günün Deyimi’nden, ‘Günün Atasözü’ne’, ‘Günün Anismasyonu/Filmi’nden ‘Günün Kelimesi’ne ve ‘Günün Tarihi Olayı’ına kadar; ‘‘GÜNÜN REÇETESİ’’ elinizin altında.
          </Typography>
          <Typography variant="body1" align="center" paragraph>
            İster siz faydalanabilirsiniz isterseniz de sevdiklerinizle ‘‘Günün Reçetesi’’ni paylaşabilirsiniz.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}

export default Features;
