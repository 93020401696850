import React from 'react';
import { Box, Container, Typography, CardMedia, List, ListItem, ListItemText } from '@mui/material';

const Features = () => {
  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} justifyContent="center" alignItems="center" pt={6}>
        <Box width={{ xs: '100%', md: '25%' }} p={3} order={{ xs: 2, md: 1 }}>
          <CardMedia
            component="img"
            image="/assets/inapp/2.png"
            alt="App Screenshot"
            sx={{ mx: 'auto', maxWidth: '100%', height: 'auto' }}
          />
        </Box>
        <Box width={{ xs: '100%', md: '50%' }} p={3} order={{ xs: 1, md: 2 }}>
          <Typography variant="h4" component="h2" fontWeight="bold" color="primary" gutterBottom sx={{ textAlign: 'center' }}>
            Ev Okulu App nedir?
          </Typography>
          <Typography variant="body1" paragraph sx={{ textAlign: 'justify' }}>
            Ev Okulu App, Eğitimin Süper APP’idir. Ev Okulu App; farklı ihtiyaçlara hizmet eden pek çok uygulamayı içinde barındıran, mali değeri 100 bin TL’ye varan sertifikalı eğitimleri ücretsiz takip edebileceğiniz, binlerce eğitim videosundan ve eğitici aktivitelerden oluşan, eğitimci ve ebeveynlerin istifadesine sunulmuş bir eğitim aplikasyonudur. Ev Okulu App ile çocuğunuz, öğrencileriniz ve danışanlarınız ile gerçekleştirebileceğiniz etkinlikleri görebilir, eğitim programlarına katılabilir, ödüllü bilgi yarışmalarında kendinizi sınayabilir, gidilebilecek ve gezilebilecek eğlenceli ve eğitsel mekanları inceleyebilir; makalelere, sesli ve video bloglara, ücretsiz e-kitaplara erişebilir ve kişisel gelişiminize katkı sunabilirsiniz.
          </Typography>
          <Typography variant="h5" component="h3" fontWeight="bold" color="secondary" gutterBottom sx={{ textAlign: 'center' }}>
            Ev Okulu App;
          </Typography>
          <List sx={{ listStyleType: 'disc', pl: 4 }}>
            <ListItem sx={{ display: 'list-item', pl: 0 }}>
              <ListItemText primary="Çocukları ile kaliteli vakit geçirmek isteyen ebeveynlere sunduğu etkinlik, eğitici aktivite ve içerikler ile ebeveynlik becerilerini geliştirmek için eğitimlere katılmak isteyen ebeveynlere" />
            </ListItem>
            <ListItem sx={{ display: 'list-item', pl: 0 }}>
              <ListItemText primary="Öğrencilerinin aynı zamanda bilişsel becerilerini de desteklemek isteyen, eğitim programlarına katılarak kendisini geliştirmek isteyen öğretmenlere" />
            </ListItem>
            <ListItem sx={{ display: 'list-item', pl: 0 }}>
              <ListItemText primary="Terapistlik becerilerini geliştirmek için eğitimler alan tüm ruh sağlığı meslek gruplarına ve öğrencilerine" />
            </ListItem>
            <ListItem sx={{ display: 'list-item', pl: 0 }}>
              <ListItemText primary="Eğitimler ile kendini geliştirmek isteyen tüm yetişkinlere" />
            </ListItem>
            <ListItem sx={{ display: 'list-item', pl: 0 }}>
              <ListItemText primary="Çocuklara özel çekilmiş atölye ve eğitimler ile çocuklara" />
            </ListItem>
          </List>
          <Typography variant="body2" align="center" sx={{ mt: 4 }}>
            Ev Okulu App, bir Süper App’tir. Yani Ev Okulu App ile ayrı ayrı app olarak çıkabilecek pek çok içeriği tek bir aplikasyonda sizler için birleştirdik.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}

export default Features;
