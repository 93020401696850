import React from 'react'
import Cominic from '../components/Contact/Cominic'

const ContactPage = () => {
  return (
    <div>
        <Cominic />
    </div>
  )
}

export default ContactPage