import React from 'react';
import Features from '../components/WhyApp/Features';
import Features1 from '../components/WhyApp/Features1';
import Features2 from '../components/WhyApp/Features2';
import Features3 from '../components/WhyApp/Features3';
import Features4 from '../components/WhyApp/Features4';

const WhyApp = () => {
  return (
    <div className=' px-4 w-10/12 mx-auto'>
      <h1 className="text-3xl font-bold text-[#A983E5] pt-24 mb-8">Uygulamanın Faydaları</h1>
      <div className=' rounded-lg'>
        <div className='py-10 '>
          <Features />
        </div>
        <div className='py-10 '>
          <Features1 />
        </div>
        <div className='py-10 '>
          <Features2 />
        </div>
        <div className='py-10 '>
          <Features3 />
        </div>
        <div className='py-10 '>
          <Features4 />
        </div>
      </div>
    </div>
  );
};

export default WhyApp;
