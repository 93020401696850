import React from 'react';
import { FaFacebook, FaTwitter, FaYoutube, FaInstagram, FaTelegram, FaLinkedin, FaEnvelope } from 'react-icons/fa';

const features = [
  {
    image: '/phone-call.png',
    text: 'Ev Okulu Derneği Tel: 0537 430 43 02 / 0530 223 74 33'
  },
  {
    image: '/telephone.png',
    text: 'Ev Rehberliği Bilgi ve Danışma Hattı: 0530 072 69 82'
  },
  {
    image: '/location.png',
    text: 'Esentepe Mahallesi, Mithat Ulu Ünlü Sokak, No: 20/B, D:1, Şişli / İSTANBUL'
  }
];

const Contact = () => {
  return (
    <div className="container mx-auto px-4 py-8 ">
      <h1 className="text-5xl font-bold text-center mb-4  text-purple-600" style={{ color: '#A983E5' }}>EV OKULU DERNEĞİ</h1> 
      <div className="flex flex-wrap pt-16">
        <div className="w-full lg:w-1/2 flex flex-col justify-center items-center py-6">
          <p className="text-2xl mb-4 text-center font-bold">Başarı Burada Başlıyor, Bizimle İletişimde Olun!</p>
          <div className="flex flex-wrap pt-16 py-6">
            {features.map((feature, index) => (
              <div key={index} className="flex items-center mb-4" style={{ minHeight: '100px', minWidth: '300px' }}>
                <img src={feature.image} alt={`Resim ${index + 1}`} className="w-28 h-28 mr-2" />
                <p style={{ fontFamily: "'Poppins', sans-serif" }} className="text-lg font-bold flex-grow">
                  {feature.text}
                </p>
              </div>
            ))}
          </div>
          <div className="grid grid-cols-4 py-10 gap-4 sm:flex sm:flex-wrap sm:justify-center sm:space-x-4">
            <div className="flex flex-col items-center">
              <a href="https://www.facebook.com/evokuludernegi/" target="_blank" rel="noopener noreferrer" className="cursor-pointer">
                <FaFacebook className="text-5xl text-blue-600 hover:text-blue-700" />
              </a>
            </div>
            <div className="flex flex-col items-center">
              <a href="https://twitter.com/evokuludernegi" target="_blank" rel="noopener noreferrer" className="cursor-pointer">
                <FaTwitter className="text-5xl text-blue-400 hover:text-blue-500" />
              </a>
            </div>
            <div className="flex flex-col items-center">
              <a href="https://www.youtube.com/channel/UC1Wnql4QuVx16abXmAp5_vA?view_as=subscriber" target="_blank" rel="noopener noreferrer" className="cursor-pointer">
                <FaYoutube className="text-5xl text-red-600 hover:text-red-700" />
              </a>
            </div>
            <div className="flex flex-col items-center">
              <a href="https://www.instagram.com/evokuludernegi/" target="_blank" rel="noopener noreferrer" className="cursor-pointer">
                <FaInstagram className="text-5xl text-pink-600 hover:text-pink-700" />
              </a>
            </div>
            <div className="flex flex-col items-center">
              <a href="https://t.me/s/evokuludernegi?before=6024" target="_blank" rel="noopener noreferrer" className="cursor-pointer">
                <FaTelegram className="text-5xl text-blue-400 hover:text-blue-500" />
              </a>
            </div>
            <div className="flex flex-col items-center">
              <a href="https://www.linkedin.com/company/evokuludernegi/" target="_blank" rel="noopener noreferrer" className="cursor-pointer">
                <FaLinkedin className="text-5xl text-blue-600 hover:text-blue-700" />
              </a>
            </div>
            <div className="flex flex-col items-center">
              <a href="mailto:evokuluappdestek@gmail.com" target="_blank" rel="noopener noreferrer" className="cursor-pointer">
                <FaEnvelope className="text-5xl text-gray-600 hover:text-gray-700" />
              </a>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-1/2 flex justify-center items-center">
          <img src="/homework.png" alt="Homework" className="max-w-full" />
        </div>
      </div>
    </div>
  );
};

export default Contact;
