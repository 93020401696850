import React from 'react';
import { Box, Typography, Container, Card, CardContent, CardMedia, List, ListItem, ListItemText } from '@mui/material';

const AboutContent = () => {
  return (
    <Box sx={{ backgroundImage: 'url(/arkaplan.png)', pt: 12, display: 'flex', flexDirection: { xs: 'column', lg: 'row' }, alignItems: 'center', justifyContent: 'center' }}>
      <Container sx={{ py: 4, color: 'text.primary', fontFamily: "'Poppins', sans-serif" }}>
        <Typography variant="h3" component="h1" gutterBottom color="primary" fontWeight="bold">
          Ev Okulu Derneği
        </Typography>
        <Typography variant="body1" paragraph>
          Çocuklar, günün uzun bir vaktini okullarında geçirmekte ve evde geçirdikleri süre içerisinde eğitim/öğretim
          çalışmalarına (ödevler, projeler, sınav çalışması vb.) devam etmektedir. Bu süreçte ebeveynlerinin desteğine
          ihtiyaç duymaktadırlar. Aynı zamanda aileler de evde çocuklarıyla kaliteli vakit geçirmek için arayış
          içerisindedir.
        </Typography>
        <Typography variant="body1" paragraph>
          Çocukları okula götürmek ve onları bazı kitaplar ve bilgisayarlarla yalnız bırakmak yeterli olmayıp ebeveynler
          çocuklarının keşiflerini ve büyümesini sürekli olarak desteklemelidir. Evde öğrenmenin okulun yerine geçmesi
          gerekmez. Eğitimin evde olması esasında okul süreçlerine de destek mekanizmalarını içinde barındırır. Ailelerin
          çocuklarının öğrenme deneyimlerine katılması hem çocukların başarısına hem de aile bağlarının kuvvetlenmesine
          yardımcı olur.
        </Typography>
        <Typography variant="body1" paragraph>
          Günümüzde her ev bir okula dönüşmekte ve ebeveynler çocuklarının gelişimini büyük bir hassasiyetle takip ederek
          eğitimlerine destek olmaya çalışmaktadır. Eğitim Evde ve Ev Okulu Derneği, her türlü eğitim sürecinin ev ve
          aile ortamında desteklenmesi, ailelerin çocuklarının öğrenme süreçlerine katılması, okul ve ev uyumunun sağlanması,
          aile bağlarının kuvvetlenmesine yardımcı olmak ve ev okulu sistemlerinin ülkemizde tanınmasını amaçlamaktadır.
          Çocukların bilişsel, duygusal, sosyal ve akademik gelişimlerine ailenin katılımını arttıracak bilgi ve beceri sahibi
          olmasını sağlayacak etkinlikler düzenlenmeyi hedeflenmektedir. Ebeveynlerin çağın gereklerine uygun çocukların
          ihtiyaçlarını karşılayacak bilgiyi ve bu bağlamda çocukların eğitimine ev ortamında destek olabilecek donanımı
          kazanmalarına destek olmaktır. Bu doğrultuda 17.10.2017 tarihinde açılan Eğitim Evde ve Ev Okulu Derneği’miz bir
          sivil toplum kuruluşu olarak hem öğrencinin hem de ailenin bu ihtiyaçlarına cevap verme amacıyla faaliyetler
          planlamaktadır.
        </Typography>
        <Card sx={{ mb: 4 }}>
          <CardContent>
            <List>
              <ListItem>
                <ListItemText primary="Ailelerin çocuklarıyla birlikte vakit geçirecekleri okul dışı faaliyetlerin etkinleştirilmesi ve geliştirilmesi için araştırmalar yapılması," />
              </ListItem>
              <ListItem>
                <ListItemText primary="Ebeveynlerin girişimcilik ruhunu destekleyen, stratejik düşünme, problem çözme, sıra dışı düşünme becerileri kazandıran, hayal güçlerini geliştiren kurs, seminer, konferans gibi eğitim çalışmaları düzenlemek ve kamu kuruluşları iş birliği ile kursların açılmasını sağlamak," />
              </ListItem>
              <ListItem>
                <ListItemText primary="Ebeveynlerin bilgiye ulaşımı kolaylaştıracak gazete, dergi, kitap, bülten gibi yayınlar çıkartmak," />
              </ListItem>
              <ListItem>
                <ListItemText primary="Bilginin hızla yayıldığı çağımızda ebeveynlerin evde eğitim sürecine devam etmeleri için gerekli bilgileri edinecekleri bilgisayar ve internet teknolojileri hakkında destek vermek," />
              </ListItem>
              <ListItem>
                <ListItemText primary="Sosyal medya, tablet ve cep telefonlarının eğitim amaçlı kullanılmasına yönelik çalışmalar yapmak," />
              </ListItem>
              <ListItem>
                <ListItemText primary="Veliler arasında iletişim ağı kurmak için sosyal etkinlikler organize etmek," />
              </ListItem>
              <ListItem>
                <ListItemText primary="Eğitim süreçlerinin okul dışında devam etmesine katkı sağlayacak ebeveyn dışındaki gönüllü üniversite öğrencileri ile ebeveynlerin buluşmasına aracılık etmek" />
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </Container>
      <CardMedia
        component="img"
        sx={{ width: { lg: '33%', xs: '100%' }, height: 'auto' }}
        image="/homework.png"
        alt="Right Image"
      />
    </Box>
  );
};

export default AboutContent;
