import React from 'react';
import { Box, Container, Typography, Card, CardContent, CardMedia, List, ListItem, ListItemText } from '@mui/material';

const Features = () => {
  return (
    <Container maxWidth="lg" sx={{ py: 8, backgroundColor: '#F5FAFD' }}>
      <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} justifyContent="center" alignItems="center">
      <Box width={{ xs: '100%', md: '25%' }} p={3} order={{ xs: 2, md: 1 }}>
          <CardMedia
            component="img"
            image="/assets/inapp/evokuluapptenelervar.png"
            alt="App Screenshot"
            sx={{ mx: 'auto', maxWidth: '100%', height: 'auto' }}
          />
        </Box>
        <Box width={{ xs: '100%', md: '50%' }} p={3} order={{ xs: 1, md: 2 }}>
          <Typography variant="h4" component="h2" fontWeight="bold" color="primary" gutterBottom sx={{ textAlign: 'center', color: 'purple' }}>
            Ev Okulu App’te neler var?
          </Typography>
          <Typography variant="body1" paragraph sx={{ textAlign: 'justify' }}>
            <List sx={{ listStyleType: 'disc', pl: 4, mb: 4 }}>
              <ListItem sx={{ display: 'list-item', pl: 0 }}>
                <ListItemText primary="Çocuğunun bütüncül gelişimini önemseyen, onu eğlenerek öğrenebileceği materyaller ve aktiviteler ile desteklemek isteyen bir ebeveynseniz," />
              </ListItem>
              <ListItem sx={{ display: 'list-item', pl: 0 }}>
                <ListItemText primary="Öğrencilerinin eğitiminde fark yaratmak isteyen bir eğitimciyseniz," />
              </ListItem>
              <ListItem sx={{ display: 'list-item', pl: 0 }}>
                <ListItemText primary="Kariyer gelişimine ve mesleki becerilerine yatırım yapmak isteyen bir uzmansanız," />
              </ListItem>
              <ListItem sx={{ display: 'list-item', pl: 0 }}>
                <ListItemText primary="Kişisel gelişimine pek çok alanda katkıda bulunmak isteyen bir bireyseniz," />
              </ListItem>
            </List>
            Uzmanların incelemeleri ve araştırmaları neticesinde özenle hazırlanmış olan eğitici aktiviteler, evde çocuğunuzla birlikte oynayabileceğiniz oyunlar, çocuk, ebeveyn ve uzmanlara yönelik çeşitli kaynaklar, alanında uzman kişiler tarafından sunulan eğitimler, nerede olursanız olun okuyabileceğiniz, izleyebileceğiniz ve dinleyebileceğiniz blog içerikleri, her ay sevdiklerinizle keyifli vakit geçirebileceğiniz mekan ve etkinlik önerilerinin yer aldığı Nerede Ne Var bölümü, gününüze renk katacak Günün Reçetesi bölümü, ödüllü bilgi yarışmaları ve ÜCRETSİZ olarak ulaşabileceğiniz içeriklerle birlikte abonelik sistemiyle hizmetinize sunulan daha pek çok alan, eğitimin süper aplikasyonu olan Ev Okulu App’te sizleri bekliyor!
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}

export default Features;
