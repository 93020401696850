import React from 'react';
import { Box, Container, Typography, CardMedia, List, ListItem, ListItemText } from '@mui/material';

const Features = () => {
  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} justifyContent="center" alignItems="center">
      <Box width={{ xs: '100%', md: '25%' }} p={3} order={{ xs: 2, md: 1 }}>
          <CardMedia
            component="img"
            image="/assets/inapp/blog.png"
            alt="App Screenshot"
            sx={{ mx: 'auto', maxWidth: '100%', height: 'auto' }}
          />
        </Box>
        <Box width={{ xs: '100%', md: '50%' }} p={3} order={{ xs: 1, md: 2 }}>
          <Typography variant="h4" component="h2" fontWeight="bold" color="primary" gutterBottom sx={{ textAlign: 'center', color: 'purple' }}>
            Ev Okulu App ‘‘Blog’’da ne var?
          </Typography>
          <Typography variant="body1" align="center" paragraph>
            Hangi bilgiyi, sağlıklı bir şekilde ve nerden edinebilirim diyor ve bu konuda zorlanıyor musunuz? Alanın uzmanları elimin altında olsun ve bilgi önüme aksın, istiyor musunuz? Ev Okulu App ‘‘BLOG’’ bölümü tam size göre.
          </Typography>
          <List sx={{ listStyleType: 'disc', pl: 4, mb: 4 }}>
            <ListItem sx={{ display: 'list-item', pl: 0 }}>
              <ListItemText primary="‘Makaleler’ bölümünden yüzlerce makaleye," />
            </ListItem>
            <ListItem sx={{ display: 'list-item', pl: 0 }}>
              <ListItemText primary="Sesli blog bölümünden evde, arabada, iş yerinde; ister telefondan ister kulaklıkla ister bluetooth ile bulunduğunuz ortamda dinleyebileceğiniz yüzlerce eğitim içeriğine," />
            </ListItem>
            <ListItem sx={{ display: 'list-item', pl: 0 }}>
              <ListItemText primary="Alandaki uzman isimlerle röportajların olduğu ‘Video Blog’ bölümüne," />
            </ListItem>
            <ListItem sx={{ display: 'list-item', pl: 0 }}>
              <ListItemText primary="Ücretsiz indirebileceğiniz binlerce kitabın olduğu ‘Kaynak Hazinesi’ne" />
            </ListItem>
          </List>
          <Typography variant="body1" align="center">
            Ev Okulu App ‘‘Blog’’ ile erişebilirsiniz.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}

export default Features;
