import React from 'react';
import { FaPlayCircle } from 'react-icons/fa'; 

const Features = () => {
  return (
    <div className="flex flex-wrap bg-gradient-to-r from-pink-100 to-yellow-100 rounded-lg border-0 shadow-xl overflow-hidden">
      <div className="w-full md:w-1/3 p-4 transform hover:scale-105 transition-transform duration-500">
        <iframe
          className="rounded-lg shadow-md"
          width="100%"
          height="315"
          src="https://www.youtube.com/embed/Yj-Rg1Czokk"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>

      <div className="w-full border pl-8 md:w-2/3 p-4" style={{ fontFamily: "'Poppins', sans-serif" }}>
        <h2 className="text-xl font-bold mb-4 flex items-center text-purple-600">Ebeveynler için Ev Okulu App ne işe yarar?</h2>
        <p> Bebeklikten itibaren 15 yaşa kadar çocuğu olan aileler için Ev Okulu Aplikasyonda;
        </p>
        <ul className="text-lg text-gray-800 list-disc">
          <li>Evde ailecek oynayabileceğiniz Ev Oyunlarını öğrenebilirsiniz,</li>
          <li>Aile içinde birbirinize sorabileceğiniz ilginç soruları görebilirsiniz,</li>
          <li>Çocuğunuzun öğrenmesi ve araştırması gereken başlıkları öğrenebilirsiniz,</li>
          <li>Ebeveyn olarak katılabileceğiniz onlarca sertifikalı eğitim programına Ev Okulu Aplikasyonda olmanın avantajlarıyla dahil olabilirsiniz,</li>
          <li>“Nerde Ne Var?” bölümü ile ailecek gidebileceğiniz etkinlikleri görebilirsiniz,
          </li>
          <li>“Sizden Gelenler” bölümü ile Ev Okulu Aplikasyon kullanıcısı ailelerin yaptığı çalışmaları görebilir ve siz de diğer kullanıcıların faydalanması için çalışmalarınızı bu bölümde paylaşabilirsiniz,
          </li>
          <li>
            “Blog” bölümünde ebeveynlik üzerine makalelere, sesli bloga, video röportajlara ve yüzlerce kitaba erişebilirsiniz,
          </li>
          <li>
            “Bilgi Yarışması” bölümünde ailecek katılabileceğiniz ve ödüller de kazanabileceğiniz yarışmalara canlı olarak katılabilirsiniz.
          </li>

        </ul>
        <p>Aile üyelerinizin tamamını kapsayacak tüm bu içerikler ve daha fazlasına Ev Okulu App ile tek tıkla ulaşabilirsiniz.</p>
      </div>
    </div>
  );
};

export default Features;
