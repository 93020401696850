import React from 'react'

const VideoPage = () => {
  return (
    <div className=' pt-20   justify-center  items-center px-4'>
        <div className="flex justify-center mb-8">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/OjHZcfLFZ60"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  )
}

export default VideoPage