import React from 'react';

const Banner = () => {
  return (
    <div className="relative overflow-hidden">
      <div className="absolute inset-0 bg-black opacity-50"></div> 
      <img src={process.env.PUBLIC_URL + '/ekibimiz.png'} alt="Ekibimiz" className="w-full h-auto max-h-[75vh] object-cover" />
      <div className="absolute inset-0 flex justify-center items-center">
        {/* <div className="text-center p-4 animate-pulse">
          <h1 style={{ fontFamily: "'Poppins', sans-serif" }} className="text-5xl font-bold animate-bounce bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-yellow-500">
            Ekibimiz
          </h1>
        </div> */}
      </div>
    </div>
  );
};

export default Banner;
