import React from "react";
import SingleQuestion from "../components/Faqcomponents/SingleQuestion";
import { questions } from "../components/Faqcomponents/questions";

export default function Faq() {
  return (
    <div className="w-full md:w-6/12 mx-auto">
      <h1 className="text-3xl font-bold text-center text-[#B594E9] mb-8">Sıkça Sorulan Sorular</h1>
      {questions.map((item, index) => (
        <SingleQuestion
          key={index}
          question={item.question}
          answer={item.answer}
          embeddedVideo={item.embeddedVideo}
        />
      ))}
    </div>
  );
}
