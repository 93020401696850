import React from 'react';
import Features from '../components/App/Features';
import Features1 from '../components/App/Features1';
import Features2 from '../components/App/Features2';
import Features3 from '../components/App/Features3';
import Features4 from '../components/App/Features4';
import Features5 from '../components/App/Features5';
import Features6 from '../components/App/Features6';
import Features7 from '../components/App/Features7';
import Features8 from '../components/App/Features8';
import Features9 from '../components/App/Features9';
import Features10 from '../components/App/Features10';
import Features11 from '../components/App/Features11';



import { FiChevronDown } from 'react-icons/fi'; 

const Appin = () => {
  return (
    <div className="text-center">
      <h1 className="text-3xl font-bold text-[#A983E5] mb-4">APP HAKKINDA BİLMENİZ GEREKENLER!</h1>
      <FiChevronDown className="animate-bounce text-[#A983E5] w-16 h-16 mx-auto mb-4" /> 
      <div className="max-w-10/12 mx-auto"> 
        <div className="">
          <Features />
        </div>
        <div className="mt-8">
          <Features1 />
        </div>
        <div className=" mt-8">
          <Features2 />
        </div>
        <div className="mt-8">
          <Features3 />
        </div>
        <div className=" mt-8">
          <Features4 />
        </div>
        <div className="mt-8">
          <Features5 />
        </div>
        <div className=" mt-8">
          <Features6 />
        </div>
        <div className="mt-8">
          <Features7 />
        </div>
        <div className="mt-8">
          <Features8 />            
        </div>
        <div className=" mt-8">
          <Features9 />
        </div>
        <div className=" mt-8">
          <Features10 />
        </div>
        <div className="mt-8">
          <Features11 />
        </div>
      </div>
    </div>
  );
}

export default Appin;

