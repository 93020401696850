import React, { useState } from 'react';
import { FiBell } from 'react-icons/fi';

const haberler = [
  {
    id: 1,
    imageUrl: '/guncellemeler/25nisann.jpeg',
    title: 'Uluslararası zirvesi yakında!',
    date: '26 Nisan 2024'
  },
  {
    id: 2,
    imageUrl: '/guncellemeler/25nisan.jpeg',
    title: 'Çocuklar için yeni eğitim programı!',
    date: '25 Nisan 2024'
  },
  {
    id: 3,
    imageUrl: '/guncellemeler/1mayis.jpeg',
    title: 'Bildirim yönetim modülü, içeriğe eklenmiştir!',
    date: '1 Mayıs 2024'
  },
];

const HaberCard = ({ imageUrl, title, date, showAlarmIcon, onClick }) => {
  return (
    <div className="max-w-md w-full mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl" onClick={onClick}>
      <div className="md:flex">
        {imageUrl && (
          <div className="md:flex-shrink-0">
            <img className="h-48 w-full object-cover md:w-48 md:h-auto" src={imageUrl} alt="Haber Görseli" />
          </div>
        )}
        <div className="p-8">
          <div className="flex items-center mb-2 justify-between"> 
            <h2 className="text-lg font-semibold">App Güncelleme</h2> 
            {showAlarmIcon && <FiBell className="w-6 h-6 mr-2 text-red-500" />} 
          </div>
          <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold">{date}</div>
          <a href="#" className="block mt-1 text-lg leading-tight font-medium text-black hover:underline">{title}</a>
        </div>
      </div>
    </div>
  );
}

function App() {
  const [popupOpen, setPopupOpen] = useState(false);

  const handleClick = () => {
    setPopupOpen(true);
  }

  const reversedHaberler = [...haberler].reverse(); 
  return (
    <div className="container mt-8 mx-auto py-8">
      <h1 className="text-3xl text-center text-purple-500 font-bold mb-4">Ev Okulu App'te Yeni Neler Var?</h1> 
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {reversedHaberler.map(haber => (
          <HaberCard
            key={haber.id}
            imageUrl={haber.imageUrl}
            title={haber.title}
            date={haber.date}
            showAlarmIcon={haber.id !== 3} 
            onClick={handleClick} 
          />
        ))}
      </div>
      {popupOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-25">
          <div className="bg-white p-8 max-w-md mx-auto rounded-md shadow-md">
            <p className="text-lg text-center">Artık gelen bildirimlerinizi uygulama ana ekranında sağ üstte yer alan BİLDİRİM simgesine tıklayarak görebilirsiniz.</p>
            <button className="block mx-auto mt-4 bg-purple-500 text-white px-4 py-2 rounded hover:bg-purple-600" onClick={() => setPopupOpen(false)}>Kapat</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
