const education = [
  {
    id: 1,
    title: "Origami Eğitimi",
    image: "/egitimler/cocuklar_icin_origami_egitimi (1) (1).jpg", 
    subtitle1:"Eğitim Amacı:",
    description1:"Origami eğitimi, kâğıt katlama sanatının büyüleyici dünyasını keşfetmek isteyen herkes için heyecan verici bir fırsat sunar. Bu eşsiz deneyim, katılımcıların yaratıcılıklarını serbest bırakmalarına, düşüncelerini kâğıda dökerek güzel eserler yaratmalarına olanak tanır. Bu eğitimle birlikte katılımcıların odaklanma becerilerinin artırılması, problem çözme becerilerini güçlendirilmesi ve estetik duygularının geliştirilmesi hedeflenmektedir.    ",
    subtitle2: "Neden Katılmalısınız?",
    description2: <div dangerouslySetInnerHTML={{ __html: "<p>Hayal gücünüzü serbest bırakıp sınırlarınızı zorlayarak yeni şekiller ve de desenler yaratabilmek için, </br>Sakinleştirici, zihni rahatlatıcı bir hobi edinebilmek için,</br>Odaklanma gerekliliği, zihinsel netliği artırır ve dikkat dağınıklığını azaltır.</brEstetik algınızla birlikte problem çözme becerinizi geliştirebilmek için bu eğitime katılmalısınız!</p>" }} />,
    subtitle3:"Eğitim İçeriği:",
    description3: <div dangerouslySetInnerHTML={{ __html: "<p> Bu eğitim programında kağıt aracılığıyla temel düzeyde çeşitli şekillerin oluşturulma sürecini takip edip aynı zamanda kendi şekillerinizi oluşturma yolunda ilk adımlarınızı atacaksınız.    </p>" }} />,
  },
  {
    id: 2,
    title: "Çalkala Beynini Atölyesi",
    image: "/egitimler/cocuklar_icin_calkala_beynini_atolyesi_ortaokul.png", 
    subtitle1:"Eğitim Amacı:",
    description1:"Bu atölye, gençlerin bedensel ve zihinsel farkındalıklarını desteklemeyi, eğlenceli aktivitelerle aktif kalmalarının önemini vurgulamayı ve sosyal becerilerini geliştirmeyi hedeflemektedir. Egzersizler, oyunlar ve etkileşimli aktiviteler aracılığıyla gençlerin sağlıklı bir yaşam tarzını benimsemelerine ve kendilerini keşfetmelerine yardımcı olunması amaçlanmaktadır.",
    subtitle2: "Neden Katılmalısınız?",
    description2: <div dangerouslySetInnerHTML={{ __html: "<p>Çocuklarınızın eğlenceli ve öğretici bir deneyim yaşaması için, <br/>  Eğlenceli oyunlar aracılığıyla çocuklarınızın sosyal becerilerini geliştirmelerine destek olmak için,   <br/> Zihinsel ve bedensel gelişime hitap eden yaratıcı aktiviteleri bir arada deneyimlemek için,  <br/> Çocuklarınızın bedensel enerjilerini sağlıklı bir şekilde yönetmelerini sağlamak için,  <br/> Beden farkındalıklarını artırabilmenize olanak sağlayacak etkinlikleri keşfetmek için bu eğitime katılmalısınız!</p>" }} />,
    subtitle3:"Eğitim İçeriği:",
    description3: <div dangerouslySetInnerHTML={{ __html: "<p> Tavşan Kovalamaca <br/> Sıfır Sekiz <br/> Beş Parmak Serçe Parmak </p>" }} />,
  },
  {
    id: 3,
    title: "Masallar Diyarı Atölyesi",
    image: "/egitimler/cocuklar_icin_masallar_diyari_atolyesi_ortaokul.png", 
    subtitle1:"Eğitim Amacı:",
    description1:"Bu atölye, ortaokul çağındaki çocuklara yönelik gerçekleştirilecek olup çocukların hayal güçlerini, el becerilerini ve problem çözme yeteneklerini geliştirmeyi amaçlamakla birlikte öğrenmeyi ve çocukların kendilerini tanıma/keşfetme süreçlerini eğlenceli hâle getirmeyi hedeflemektedir. ",
    subtitle2: "Neden Katılmalısınız?",
    description2: <div dangerouslySetInnerHTML={{ __html: "<p>Birbirinden farklı ve özenle seçilmiş masallardan oluşan bir diyarı keşfetmek mi istiyorsunuz?</p><p>Çocuklarınızın hayal güçleri ile süsleyerek çeşitlendirebilecekleri masallar arıyor ama bulamıyor musunuz?</p><p>Çocuklarınızla kaliteli vakit geçirmek için ne yapacağınızı seçmekte güçlük mü çekiyorsunuz?</p><p>Masallar eşliğinde çocuklarınızın bilişsel gelişimine katkıda bulunmak mı istiyorsunuz?</p><p>O halde bu atölye tam da sizlere göre!</p>" }} />,
    subtitle3:"Eğitim İçeriği:",
    description3: <div dangerouslySetInnerHTML={{ __html: "<p>Akıllı Kız ile Korkak Dev Masalı</p><br><p>Aslan ile Fare Masalı</p><br><p>Çiçek Kelebekleri Masalı</p><br><p>Çirkin Ördek Yavrusu Masalı</p><br><p>Fareli Köyün Kavalcısı Masalı</p><br><p>İnatçı Keçi Masalı</p><br><p>İncir Ağacı ile Mango Ağacı Masalı</p><br><p>Kayıp Ay Dede Masalı</p><br><p>Keloğlan’ın Umudu Masalı</p><br><p>Kibirli Fare ve Deve Masalı</p><br><p>Prens ve Büyülü Balık Masalı</p><br><p>Şeker Perisi Masalı</p><br><p>Tavşan ile Kaplumbağa Masalı</p><br><p>Uyumak İstemeyen Zürafa Masalı</p>" }} />,
  },
  {
    id: 4,
    title: "Sanatsal Tasarım Atölyesi",
    image: "/egitimler/cocuklar_icin_sanatsal_tasarim_atolyesi_ortaokul.png", 
    subtitle1:"Eğitim Amacı:",
    description1:"Bu atölye programıyla birlikte birbirinden farklı ve özel masallar eşliğinde hem çocukların hayal dünyalarının genişletilmesi hem de ebeveynlerin çocuklarına istediği zamanlarda dinletebilecekleri çeşitli masalların aktarımının yapılması amaçlanmaktadır. Bununla birlikte atölye kapsamında yer verilen masallar eşliğinde çocuk ve ebeveyn ilişkisinin desteklenebilmesi ve kuvvetli bir bağ oluşabilmesi amaçlanmaktadır.",
    subtitle2: "Neden Katılmalısınız?",
    description2: <div dangerouslySetInnerHTML={{ __html: "<p>Üretkenlik ve problem çözme becerilerini eğlenceli bir şekilde geliştirmek istiyorsanız,</p><br><p>Çocuğunuzun kendini ifade etme becerisini desteklemek istiyorsanız,</p><br><p>Tasarımlar eşliğinde çocuğunuzun iç dünyasını dışa yansıtabilmesini istiyorsanız,</p><br><p>Sanatın yaşam boyu olan faydalarını çocuğunuzla birlikte birinci elden deneyimlemek istiyorsanız bu atölye çalışmasına katılmalısınız!</p> " }} />,
    subtitle3:"Eğitim İçeriği:",
    description3: <div dangerouslySetInnerHTML={{ __html: "<p>Keçeden Oyuncak Bebek Yapımı</p><br><p>Ben Picassoyum</p><br><p>Doğaçlama</p><br><p>El Becerisi Geliştirme</p><br><p>Oyunlar ve Eğlenceli Aktiviteler</p><br><p>Modelleme ve Tasarım</p>" }} />,
  },
  {
    id: 5,
    title: "Yazarlık Atölyesi",
    image: "/egitimler/cocuklar_icin_yazarlik_atolyesi_ortaokul.png", 
    subtitle1:"Eğitim Amacı:",
    description1:"Okumuş olduğumuz hikâyelerde ve romanlarda kimi zaman yazarın kurguladığı olay örgüsü kimi zamansa yazarın anlatım tarzı içimizde var olan merak duygusunu ilk sayfadan son sayfaya kadar uyanık tutar. Kurmaca usulü yazılan bu edebi metinlerde yazarların yazım anlamındaki tercihleri o eserin ne kadar vurucu olduğunu belirleyen faktörlerden birisi olarak karşımıza çıkar.Bu eğitim programıyla birlikte çocukların yazım süreçlerinde kullanabilecekleri anlatım tekniklerini keşfetmesi, olay örgüsünü kurarken yararlanabilecekleri stratejileri öğrenmeleri ve bu doğrultuda içsel dünyalarında var olan malzemeyi kâğıda dökerek etkili metinler/hikâyeler yazabilmelerine olanak sağlayacak becerilerin kazandırılması amaçlanmaktadır.",
    subtitle2: "Neden Katılmalısınız?",
    description2: <div dangerouslySetInnerHTML={{ __html: "<p>Çocuğunuzun yazım becerilerini desteklemek için,</p><br><p>Çocuğunuzun yazı dilinde fark yaratabilecek anlatım tekniklerini öğrenebilmesi için,</p><br><p>Çocuğunuzun hayal dünyasını kelimeler aracılığıyla kâğıda dökebilmesini sağlamak için,</p><br><p>Çocuğunuzun dili kullanım becerisini desteklemek için bu eğitim programına katılmalısınız!</p>" }} />,
    subtitle3:"Eğitim İçeriği:",
    description3: <div dangerouslySetInnerHTML={{ __html: "<p>Eleştirel Okuma ve Yazma Bağlantısı</p><br><p>Tema Bazlı Yazma</p><br><p>Üretken Yazma Teknikleri</p><br><p>Kelimelerin Önemi</p><br><p>Dil Hâkimiyeti</p><br><p>Tavsiyeler</p>" }} />,
  },
  {
    id: 6,
    title: "Kalimba Eğitimi",
    image: "/egitimler/cocuklar_icin_kalimba_egitimi_ortaokul.png", 
    subtitle1:"Eğitim Amacı:",
    description1:"Bu atölye çalışmasıyla ortaokul çağındaki çocukların, başparmak piyanosu olarak da bilinen “kalimba” eşliğinde müzikal bir serüvene adım atmaları amaçlanmıştır. Bu yolculukta, çocukların; müzikal becerilerini geliştirmek, farkındalıklarını artırmak ve iş birlikçi öğrenmelerini sağlamak, kişisel gelişimlerine destek olmak, öz güven ve disiplin gelişimlerine katkıda bulunmak, hayal güçlerini kullanmaya yönelik yaratıcılıklarını artırmak hedeflenmiştir.    ",
    subtitle2: "Neden Katılmalısınız?",
    description2: <div dangerouslySetInnerHTML={{ __html: "<p>Çocuğunuzun kalimba ile kapsamlı bir müzik eğitimi almasını sağlayarak holistik gelişimine destek olmak istiyorsanız,</p><br><p>Müziğin eşsiz öğretici yoluyla yaratıcılığını ifade edebileceği alternatif bir yol göstermek istiyorsanız,</p><br><p>Müzikal aktiviteler sayesinde çocuğunuzun stresini atmasında ve duygusal regülasyonunu sağlamasında destek olmayı amaçlıyorsanız,</p><br><p>Kaliteli, eğlenceli ve eğitici vakit geçirmesini sağlayarak sosyalleşmesine katkıda bulunmak istiyorsanız bu eğitime katılmalısınız!</p>" }} />,
    subtitle3:"Eğitim İçeriği:",
    description3: <div dangerouslySetInnerHTML={{ __html: "<p>Kalimba ile Tanışalım</p><br><p>Sayılara Denk Gelen Notalar</p><br><p>Kalimbamızı Akort Ediyoruz</p><br><p>Parmak Egzersizleri</p><br><p>Arkadaşım Eşek</p><br><p>Bana Bir Masal Anlat Baba</p><br><p>Hayat Bayram Olsa</p><br><p>Fikrimin İnce Gülü</p><br><p>Bugün Bayram</p>" }} />,
  },
  {
    id: 7,
    title: "İşaret Dili Eğitimi",
    image: "/egitimler/cocuklar_icin_isaret_dili_egitimi_ortaokul.png", 
    subtitle1:"Eğitim Amacı:",
    description1:"Bu eğitimle birlikte işitme engelli bireylerle daha iyi iletişim kurabilme yeteneğini geliştirmek hedeflenmektedir. Bunun yanı sıra, işaret dilinin temelini öğreterek çocukların bu dili kullanarak işitme engelli bireylerle etkileşimde bulunmalarını sağlamak, empati ve hoşgörüyü artırarak farklılıklara saygı duyma ve anlayışlı bireyler olmalarını desteklemek amaçlanmaktadır. Çocukların dil becerilerini geliştirerek iletişimde daha etkili olmalarını sağlamak da bu eğitimin hedefleri arasındadır. ",
    subtitle2: "Neden Katılmalısınız?",
    description2: <div dangerouslySetInnerHTML={{ __html: "<p>İşitme engelli bireylerle etkili bir şekilde iletişim kurmalarını sağlayarak çocukların duygularını ifade etme yeteneklerini desteklemek için,</p><br><p>Çocukların farklı iletişim yöntemlerini öğrenerek sahip oldukları dünyayı genişletmelerine katkıda bulunmak için,</p><br><p>İşitme engelli bireylerin deneyimlerini anlama fırsatı vererek çocukların duyarlılıklarını artırmak için,</p><br><p>Çocuğunuzun dil gelişimini desteklemek için bu eğitime katılmalısınız!</p>" }} />,
    subtitle3:"Eğitim İçeriği:",
    description3: <div dangerouslySetInnerHTML={{ __html: "<p>Hayvanlar</p><br><p>İşaret Dili Alfabesi</p><br><p>Meslekler</p><br><p>Renkler</p><br><p>Sayılar</p><br><p>Selamlaşma</p><br><p>Zaman</p>" }} />,
  },
  {
    id: 8,
    title: "Yunanca Temel Düzey Eğitimi",
    image: "/egitimler/cocuklar_icin_yunanca_temel_duzey_egitimi_ortaokul.png",
    subtitle1:"Eğitim Amacı:",
    description1:"Bu eğitimin temel hedefi, katılımcıların dil becerilerini geliştirerek Yunancayı etkin bir şekilde kullanabilmelerini sağlamaktır. Bu bağlamda eğitimi alacak olan kişilerin; sözlü ve yazılı iletişim becerilerini güçlendirmek, dil bilgisine hâkim olmalarını sağlamak, okuma ve dinleme becerilerini artırmak gibi hedeflere odaklanılmaktadır. Buna ek olarak kişilere kültürel açıdan farklı perspektifler sunmak, küresel bir vizyona sahip olmalarında ve dünya çapında iletişim kurmalarında onları etkin hâle getirmek gibi pek çok kazanımı elde etmeleri hedeflenmektedir. ",
    subtitle2: "Neden Katılmalısınız?",
    description2: <div dangerouslySetInnerHTML={{ __html: "<p>Eğlenceli şekilde yeni bir dil öğrenirken aynı zamanda yeni bir kültürle de tanışmak istiyorsanız,</p><br><p>Yabancı dil yeteneğinizi geliştirerek zihinsel becerilerinize katkı sağlamak istiyorsanız,</p><br><p>Kişisel gelişiminize katkıda bulunmak istiyorsanız,</p><br><p>Yeni iş, kariyer ve eğitim imkanlarına sahip olma yolunda ilk adımlarınızı atmak istiyorsanız bu eğitime katılmalısınız!</p>" }} />,
    subtitle3:"Eğitim İçeriği:",
    description3: <div dangerouslySetInnerHTML={{ __html: "<p>Alfabe</p><br>Tonlama & Sesli Harfler<br>Aile Bireyleri<br>Doğayı Tanımak<br>Duygular<br>Günler ve Aylar<br>Hava Durumu<br>Hayvanlar<br>I'ler, O'lar, E'ler<br>Kız-Erkek Eşya Ekleri<br>Meslekler<br>Mevsimler<br>Renkler<br>Saatler<br>Sayılar<br>Soru Cümleleri<br>Şimdiki Zaman<br>Tanışma<br>Vücut Bölümleri<br>Zamirler" }} />,
  },
  {
    id: 9,
    title: "Rusça Temel Düzey Eğitimi",
    image: "/egitimler/cocuklar_icin_rusca_temel_duzey_egitimi_ortaokul.png",
    subtitle1:"Eğitim Amacı:",
    description1:"Bu eğitimin temel hedefi, katılımcıların dil becerilerini geliştirerek Rusçayı etkin bir şekilde kullanabilmelerini sağlamaktır. Bu bağlamda eğitimi alacak olan kişilerin; sözlü ve yazılı iletişim becerilerini güçlendirmek, dil bilgisine hâkim olmalarını sağlamak, okuma ve dinleme becerilerini artırmak gibi hedeflere odaklanılmaktadır. Buna ek olarak kişilere kültürel açıdan farklı perspektifler sunmak, küresel bir vizyona sahip olmalarında ve dünya çapında iletişim kurmalarında onları etkin hâle getirmek gibi pek çok kazanımı elde etmeleri hedeflenmektedir.    ",
    subtitle2: "Neden Katılmalısınız?",
    description2: <div dangerouslySetInnerHTML={{ __html: "<p>Eğlenceli şekilde yeni bir dili öğrenirken aynı zamanda yeni bir kültürle de tanışmak istiyorsanız,</p><br><p>Yabancı dil yeteneğinizi geliştirerek zihinsel becerilerinize katkı sağlamak istiyorsanız,</p><br><p>Kişisel gelişiminize katkıda bulunmak istiyorsanız,</p><br><p>Yeni iş, kariyer ve eğitim imkanlarına sahip olma yolunda ilk adımlarınızı atmak istiyorsanız bu eğitime katılmalısınız!</p>" }} />,
    subtitle3:"Eğitim İçeriği:",
    description3: <div dangerouslySetInnerHTML={{ __html: "<p>Aile Bireyleri</p><br>Alfabe<br>Benzer Kelimeler<br>“C” Eki<br>Cinsiyetler<br>Günler ve Ekleri<br>Hakkında<br>Hayvanlar<br>“İçin” Eki<br>İzin-Yasak<br>Mevsimler ve Aylar<br>Meyveler<br>Olumsuz Cümle Yapısı<br>Renkler<br>Sayılar<br>Soru Eki<br>Soru Kalıpları<br>Tanışma<br>Ülkeler<br>Yönler<br>Zamirler<br>Zıt Anlamlı Kelimeler" }} />,
  },
  {
    id: 10,
    title: "Korece Temel Düzey Eğitimi",
    image: "/egitimler/cocuklar_icin korece_temel_duzey_egitimi_okul_oncesi.png",
    subtitle1:"Eğitim Amacı:",
    description1:"Bu eğitimin temel hedefi, katılımcıların dil becerilerini geliştirerek Koreceyi etkin bir şekilde kullanabilmelerini sağlamaktır. Bu bağlamda eğitimi alacak olan kişilerin; sözlü ve yazılı iletişim becerilerini güçlendirmek, dil bilgisine hâkim olmalarını sağlamak, okuma ve dinleme becerilerini artırmak gibi hedeflere odaklanılmaktadır. Buna ek olarak kişilere kültürel açıdan farklı perspektifler sunmak, küresel bir vizyona sahip olmalarında ve dünya çapında iletişim kurmalarında onları etkin hâle getirmek gibi pek çok kazanımı elde etmeleri hedeflenmektedir",
    subtitle2: "Neden Katılmalısınız?",
    description2: <div dangerouslySetInnerHTML={{ __html: "<p>Eğlenceli şekilde yeni bir dil öğrenirken aynı zamanda yeni bir kültürle de tanışmak istiyorsanız,</p><br><p>Yabancı dil yeteneğinizi geliştirerek zihinsel becerilerinize katkı sağlamak istiyorsanız,</p><br><p>Kişisel gelişiminize katkıda bulunmak istiyorsanız,</p><br><p>Yeni iş, kariyer ve eğitim imkanlarına sahip olma yolunda ilk adımlarınızı atmak istiyorsanız bu eğitime katılmalısınız!</p>" }} />,
    subtitle3:"Eğitim İçeriği:",
    description3: <div dangerouslySetInnerHTML={{ __html: "<p>Boş Zaman ve Plan Yapmak</p><br><p>Çift Ünsüzler</p><br><p>Duygular</p><br><p>Hava Durumu ve Mevsimler</p><br><p>Hayvanlar</p><br><p>Hobiler</p><br><p>Kişilik Özellikleri</p><br><p>Kıyafetler</p><br><p>Mekanlar ve Nesneler</p><br><p>Meyveler ve Sebzeler</p><br><p>Sağlık ve Spor</p><br><p>Sayılar</p><br><p>Sesli Harfler</p><br><p>Sıra Sayıları</p><br><p>Tanışma ve Selamlaşma</p><br><p>Ünsüz Harfler</p><br><p>Vücut</p><br><p>Yemeğin Tadını İfade Etmek</p><br><p>Yön ve Ulaşım Araçları</p><br><p>Zaman</p><br><p>Zamirler ve Soru Kalıpları</p>" }} />,
  },
  {
    id: 11,
    title: "Fransızca Temel Düzey Eğitimi",
    image: "/egitimler/cocuklar_icin_fransizca_temel_duzey_egitimi_ortaokul.png",
    subtitle1:"Eğitim Amacı:",
    description1:"Bu eğitimin temel hedefi, katılımcıların dil becerilerini geliştirerek Fransızcayı etkin bir şekilde kullanabilmelerini sağlamaktır. Bu bağlamda eğitimi alacak olan kişilerin; sözlü ve yazılı iletişim becerilerini güçlendirmek, dil bilgisine hâkim olmalarını sağlamak, okuma ve dinleme becerilerini artırmak gibi hedeflere odaklanılmaktadır. Buna ek olarak kişilere kültürel açıdan farklı perspektifler sunmak, küresel bir vizyona sahip olmalarında ve dünya çapında iletişim kurmalarında onları etkin hâle getirmek gibi pek çok kazanımı elde etmeleri hedeflenmektedir.    ",
    subtitle2: "Neden Katılmalısınız?",
    description2: <div dangerouslySetInnerHTML={{ __html: "<p>Eğlenceli şekilde yeni bir dil öğrenirken aynı zamanda yeni bir kültürle de tanışmak istiyorsanız,</p><br><p>Yabancı dil yeteneğinizi geliştirerek zihinsel becerilerinize katkı sağlamak istiyorsanız,</p><br><p>Kişisel gelişiminize katkıda bulunmak istiyorsanız,</p><br><p>Yeni iş, kariyer ve eğitim imkanlarına sahip olma yolunda ilk adımlarınızı atmak istiyorsanız bu eğitime katılmalısınız!</p>" }} />,
    subtitle3:"Eğitim İçeriği:",
    description3: <div dangerouslySetInnerHTML={{ __html: "<p>Alfabe</p><br><p>Tanışma</p><br><p>Aile</p><br><p>Artikel</p><br><p>Belirtme Cümleleri</p><br><p>Fransızca Fiiller 1</p><br><p>Fransızca Fiiller 2</p><br><p>Fransızca Fiiller 3</p><br><p>Günler ve Aylar</p><br><p>Hayvanlar</p><br><p>İyelik</p><br><p>Meslekler</p><br><p>Mevsimler ve Hava Durumu</p><br><p>Renkler</p><br><p>Sayılar</p><br><p>Tanışma</p><br><p>Telaffuz</p><br><p>Vücudun Bölümleri</p><br><p>Yer-Yön</p><br><p>Zamirler</p>" }} />,
  },
  {
    id: 12,
    title: "Japonca Temel Düzey Eğitimi",
    image: "/egitimler/cocuklar_icin_japonca_temel_duzey_egitimi_ortaokul.png",
    subtitle1:"Eğitim Amacı:",
    description1:"Bu eğitimin temel hedefi, katılımcıların dil becerilerini geliştirerek Japoncayı etkin bir şekilde kullanabilmelerini sağlamaktır. Bu bağlamda eğitimi alacak olan kişilerin; sözlü ve yazılı iletişim becerilerini güçlendirmek, dil bilgisine hâkim olmalarını sağlamak, okuma ve dinleme becerilerini artırmak gibi hedeflere odaklanılmaktadır. Buna ek olarak kişilere kültürel açıdan farklı perspektifler sunmak, küresel bir vizyona sahip olmalarında ve dünya çapında iletişim kurmalarında onları etkin hâle getirmek gibi pek çok kazanımı elde etmeleri hedeflenmektedir.    ",
    subtitle2: "Neden Katılmalısınız?",
    description2: <div dangerouslySetInnerHTML={{ __html: "<p>Eğlenceli şekilde yeni bir dil öğrenirken aynı zamanda yeni bir kültürle de tanışmak istiyorsanız,</p><br><p>Yabancı dil yeteneğinizi geliştirerek zihinsel becerilerinize katkı sağlamak istiyorsanız,</p><br><p>Kişisel gelişiminize katkıda bulunmak istiyorsanız,</p><br><p>Yeni iş, kariyer ve eğitim imkanlarına sahip olma yolunda ilk adımlarınızı atmak istiyorsanız bu eğitime katılmalısınız!</p>" }} />,
    subtitle3:"Eğitim İçeriği:",
    description3: <div dangerouslySetInnerHTML={{ __html: "<p>Aylar</p><br><p>Bu Nedir?</p><br><p>Ekler</p><br><p>Fiiller</p><br><p>Geçmiş Zaman</p><br><p>Geniş Zaman</p><br><p>Günler</p><br><p>H Sütunu</p><br><p>K Sütunu</p><br><p>M Sütunu</p><br><p>Mevsimler</p><br><p>N Sütunu</p><br><p>Özneler</p><br><p>R Sütunu</p><br><p>S Sütunu</p><br><p>Sayılar</p><br><p>Ses Değişimi</p><br><p>Sesli Harfler</p><br><p>Sıfatlar</p><br><p>Soru Kalıpları</p><br><p>Şimdiki Zaman</p><br><p>T Sütunu</p><br><p>Tanışma</p><br><p>Ülkeler</p><br><p>Y ve W Sütunu</p><br><p>Yaş</p><br><p>Yer Bildirme Kalıpları</p><br><p>Yönler</p>" }} />,
  },
  {
    id: 13,
    title: "İngilizce Temel Düzey Eğitimi",
    image: "/egitimler/cocuklar_icin_ingilizce_temel_duzey_egitimi_ortaokul.png",
    subtitle1:"Eğitim Amacı:",
    description1:"Bu eğitimin temel hedefi, katılımcıların dil becerilerini geliştirerek İngilizceyi etkin bir şekilde kullanabilmelerini sağlamaktır. Bu bağlamda eğitimi alacak olan kişilerin; sözlü ve yazılı iletişim becerilerini güçlendirmek, dil bilgisine hâkim olmalarını sağlamak, okuma ve dinleme becerilerini artırmak gibi hedeflere odaklanılmaktadır. Buna ek olarak kişilere kültürel açıdan farklı perspektifler sunmak, küresel bir vizyona sahip olmalarında ve dünya çapında iletişim kurmalarında onları etkin hâle getirmek gibi pek çok kazanımı elde etmeleri hedeflenmektedir.    ",
    subtitle2: "Neden Katılmalısınız?",
    description2: <div dangerouslySetInnerHTML={{ __html: "<p>Eğlenceli şekilde yeni bir dil öğrenirken aynı zamanda yeni bir kültürle de tanışmak istiyorsanız,</p><br><p>Yabancı dil yeteneğinizi geliştirerek zihinsel becerilerinize katkı sağlamak istiyorsanız,</p><br><p>Kişisel gelişiminize katkıda bulunmak istiyorsanız,</p><br><p>Yeni iş, kariyer ve eğitim imkanlarına sahip olma yolunda ilk adımlarınızı atmak istiyorsanız bu eğitime katılmalısınız!</p>" }} />,
    subtitle3:"Eğitim İçeriği:",
    description3: <div dangerouslySetInnerHTML={{ __html: "<p>Alfabe</p><br><p>Bu-Şu-O</p><br><p>Tanışma</p><br><p>Sayılar</p><br><p>Sıra Sayıları ve Tarih</p><br><p>Yer-Yön</p><br><p>Can-Can’t</p><br><p>Aile Bireyleri</p><br><p>Dış Görünüş</p><br><p>Şimdiki Zaman</p><br><p>Geçmiş Zaman</p><br><p>Geniş Zaman</p><br><p>Gelecek Zaman</p><br><p>Günler ve Aylar</p><br><p>Meslekler</p><br><p>Mevsimler ve Hava Durumu</p><br><p>Renkler</p><br><p>Soru Cümleleri</p><br><p>Zamirler</p><br><p>Olumsuz Cümleler</p>" }} />,
  },
  {
    id: 14,
    title: "İspanyolca Temel Düzey Eğitimi",
    image: "/egitimler/cocuklar_icin_ispanyolca_temel_duzey_egitimi_ortaokul.png",
    subtitle1:"Eğitim Amacı:",
    description1:"Bu eğitimin temel hedefi, katılımcıların dil becerilerini geliştirerek İspanyolcayı etkin bir şekilde kullanabilmelerini sağlamaktır. Bu bağlamda eğitimi alacak olan kişilerin; sözlü ve yazılı iletişim becerilerini güçlendirmek, dil bilgisine hâkim olmalarını sağlamak, okuma ve dinleme becerilerini artırmak gibi hedeflere odaklanılmaktadır. Buna ek olarak kişilere kültürel açıdan farklı perspektifler sunmak, küresel bir vizyona sahip olmalarında ve dünya çapında iletişim kurmalarında onları etkin hâle getirmek gibi pek çok kazanımı elde etmeleri hedeflenmektedir.",
    subtitle2: "Neden Katılmalısınız?",
    description2: <div dangerouslySetInnerHTML={{ __html: "<p>Eğlenceli şekilde yeni bir dil öğrenirken aynı zamanda yeni bir kültürle de tanışmak istiyorsanız,</p><br><p>Yabancı dil yeteneğinizi geliştirerek zihinsel becerilerinize katkı sağlamak istiyorsanız,</p><br><p>Kişisel gelişiminize katkıda bulunmak istiyorsanız,</p><br><p>Yeni iş, kariyer ve eğitim imkanlarına sahip olma yolunda ilk adımlarınızı atmak istiyorsanız bu eğitime katılmalısınız!</p>" }} />,
    subtitle3:"Eğitim İçeriği:",
    description3: <div dangerouslySetInnerHTML={{ __html: "<p>Alfabe</p><br><p>Aile Bireyleri</p><br><p>Artikel</p><br><p>Fiiller</p><br><p>Fiiller 2</p><br><p>Gelecek Zaman</p><br><p>Geniş Zaman</p><br><p>Hayvanlar</p><br><p>İşaret Zamiri</p><br><p>İyelikler</p><br><p>Meslekler ve Ser Fiili</p><br><p>Mevsimler ve Aylar</p><br><p>Renkler</p><br><p>Saat</p><br><p>Sayılar</p><br><p>Sıfatlar</p><br><p>Tanışma</p><br><p>Yiyecekler</p>" }} />,
  },
  {
    id: 15,
    title: "İçinizdeki Sanatçı Eğitimi",
    image: "/egitimler/cocuklar_icin_icimizdeki_sanatci_atolyesi_ortaokul.png", 
    subtitle1:"Eğitim Amacı:",
    description1:"Bu eğitim ortaokul çağındaki çocukları sanatın derinliklerine doğru bir keşif yolculuğuna çıkararak yaratıcı düşünme becerilerini geliştirmeyi hedeflemektedir. Çocukları sanatsal ifadeye teşvik ederken aynı zamanda sanata duyarlılık kazanmalarını sağlamayı amaçlamaktadır. Farklı sanat disiplinlerini deneyimleyerek özgün eserler oluşturma becerilerini geliştirirken iş birliği ve takım çalışmasıyla sosyal becerilerini güçlendirme fırsatı sunmak ve katılımcıların öz güvenlerini artırarak kendilerini daha iyi ifade etme yeteneklerini geliştirmelerine yardımcı olmak hedeflenmektedir.",
    subtitle2: "Neden Katılmalısınız?",
    description2: <div dangerouslySetInnerHTML={{ __html: "<p>Çocuğunuzun hem hayal dünyasını hem de ifade becerilerini desteklemek için,</p><br><p>Çocuğunuzun sanatsal değerli yüksek özgün parçalar oluşturabilmesine yardımcı olabilmek için</p><br><p>Çocuğunuzun içsel dünyasını sanat aracılığı ile dışa vurmasına kapı aralamak için,</p><br><p>Yeni bir hobi edinebilmek için bu eğitime katılmalısınız!</p>" }} />,
    subtitle3:"Eğitim İçeriği:",
    description3: <div dangerouslySetInnerHTML={{ __html: "<p>Jackson Pollock Etkisi</p><br><p>OP Art (Optik Sanat)</p><br><p>Pastel Boya ile Kolay Manzara</p><br><p>Renk Çemberi (Ana Renkler-Ara Renkler)</p>" }} />,
  },

  
  

];

export default education;
