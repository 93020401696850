export default function Example() {
    return (
      <div className="">
        <div className="mx-auto m-2 max-w-7xl lg:px-4">
          <div className="relative isolate overflow-hidden bg-gray-100 px-4 shadow-2xl sm:rounded-3xl sm:px-8 md:pt-8 lg:flex lg:items-center lg:gap-x-8 lg:px-8 lg:pt-0">
            <svg
              viewBox="0 0 1024 1024"
              className="absolute left-1/2 top-1/2 -z-10 h-96 w-96 -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-40 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
              aria-hidden="true"
            >
              <circle cx={512} cy={512} r={512} fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fillOpacity="0.5" />
              <defs>
                <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                  <stop stopColor="#D1D5DB" />
                  <stop offset={1} stopColor="#E5E7EB" />
                </radialGradient>
              </defs>
            </svg>
            <div className="flex flex-col lg:flex-row items-center justify-between lg:gap-x-8 w-full">
              <div className="flex flex-col justify-center mx-auto max-w-md text-center lg:text-left lg:mx-0 lg:flex-auto lg:h-full lg:justify-center">
                <p className="text-sm font-bold tracking-tight text-black sm:text-base">
                  Çocuğunuz Okula Hazır mı? Metropolitan Okul Olgunluğu Testi ile Çocuğunuzun Hazırlığını Değerlendirin
                  <br />
                </p>
                <p className="mt-2 text-xs leading-5 text-gray-700">
                  Metropolitan Okul Olgunluğu Testi uygulamaları; Ev Okulu App abonelerine özel, 1490 TL’lik uygulama ve değerlendirme danışmanlığı ücreti yerine, kontenjanla sınırlı olarak yalnızca 99 TL kapsamında yürütülecektir. Ev Okulu App uygulaması içerisindeki “Shop” kısmında yer alan “Zeka-Yetenek Testi” bölümünden başvurabilirsiniz!
                </p>
              </div>
              <div className="relative my-4 lg:my-0 lg:flex lg:items-center lg:justify-end lg:w-1/2 lg:h-full">
                <img
                  className="w-full max-w-full rounded-md bg-white/5 ring-1 ring-white/10 object-contain"
                  src="/EDUBANNER.jpeg"
                  alt="App screenshot"
                  style={{ maxHeight: '100%', maxWidth: '100%' }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  