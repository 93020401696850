import React from 'react';
import { Box, Container, Grid, Typography, Card, CardContent, CardMedia } from '@mui/material';

const features = [
  { id: 1, image: '/resim5.png', title: 'DİSİPLİNLERARASILIK', description: 'Birey ve ailelere verilen destek hizmetlerinde farklı uzmanlık alanlarıyla işbirliği içinde çalışıyoruz.' },
  { id: 2, image: '/resim6.png', title: 'YENİLİKÇİLİK', description: 'Günümüz dünyasına değil geleceğin dünyasına hazırlıyoruz.' },
  { id: 3, image: '/resim7.png', title: 'ULAŞILABİLİRLİK', description: 'Bilginin paha biçilemez olduğu felsefesinden hareketle pek çok içeriği ücretsiz sunuyoruz.' },
  { id: 4, image: '/resim8.png', title: 'BECERİ ODAKLILIK', description: 'Eğitimde akademik skorlardan ziyade ilgi, yetenek, beceri ve karakter gelişimini önemsiyoruz.' },
];

const AboutFeatures3 = () => {
  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <Grid container spacing={4} alignItems="center" justifyContent="center">
        {features.map((feature) => (
          <Grid item key={feature.id} xs={12} sm={6} md={6}>
            <Card sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, alignItems: 'center', textAlign: { xs: 'center', sm: 'left' }, m: 2, p: 2 }}>
              <CardMedia
                component="img"
                image={feature.image}
                alt={`Resim ${feature.id}`}
                sx={{ width: 128, height: 128, m: 2 }}
              />
              <CardContent>
                <Typography variant="h6" component="h3" fontWeight="bold" sx={{ color: '#F79007', mb: 2 }}>
                  {feature.title}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {feature.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default AboutFeatures3;
