import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Service = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className=" pb-12 pt-20 dark:bg-dark lg:pb-[90px] lg:pt-[120px]">
      <div className="container mx-auto">
        <div className="-mx-4 flex flex-wrap">
          <div className="w-full px-4">
            <div className="mx-auto mb-12 max-w-[510px] text-center lg:mb-20">
              <span
                style={{ fontFamily: "'Poppins', sans-serif" }}
                className="mb-2 block text-4xl font-semibold text-primary"
              >
                Yorumlarınız Bizler İçin Çok Değerli!
              </span>
            </div>
          </div>
        </div>

        <Slider {...settings}>
          <ServiceImage imageUrl="/yorum.jpeg" />
          <ServiceImage imageUrl="/yorum1.jpeg" />
          <ServiceImage imageUrl="/yorum2.jpeg" />
          <ServiceImage imageUrl="/yorum3.jpeg" />
          <ServiceImage imageUrl="/yorum4.jpeg" />
          <ServiceImage imageUrl="/yorum6.png" />
        </Slider>
      </div>
    </section>
  );
};

export default Service;

const ServiceImage = ({ imageUrl }) => {
  return (
    <div className="mx-4">
      <img src={imageUrl} alt="Service" className="rounded-lg" />
    </div>
  );
};
