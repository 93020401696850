import React from 'react';
import { Box, Container, Typography, CardMedia } from '@mui/material';

const Features = () => {
  return (
    <Container maxWidth="lg" sx={{ py: 8, backgroundColor: '#F5FAFD' }}>
      <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} justifyContent="center" alignItems="center">
      <Box width={{ xs: '100%', md: '25%' }} p={3} order={{ xs: 2, md: 1 }}>
          <CardMedia
            component="img"
            image="/assets/inapp/bilgi.png"
            alt="App Screenshot"
            sx={{ mx: 'auto', maxWidth: '100%', height: 'auto' }}
          />
        </Box>
        <Box width={{ xs: '100%', md: '50%' }} p={3} order={{ xs: 1, md: 2 }}>
          <Typography variant="h4" component="h2" fontWeight="bold" color="primary" gutterBottom sx={{ textAlign: 'center', color: 'purple' }}>
            Ev Okulu App ‘‘Bilgi Yarışması’’nda ne var?
          </Typography>
          <Typography variant="body1" align="center" paragraph>
            Televizyonda gördüğünüz ve ‘‘Aslında ben de cevabı biliyorum ve katılsaydım belki ben de kazanırdım.’’ dediğiniz yarışmalar oluyor mu? Ev Okulu App Bilgi Yarışması ile siz de artık bir bilgi yarışmacısı olabilirsiniz. Her hafta CANLI ÖDÜLLÜ Bilgi Yarışmalarında kendinizi sınayabilir, LAPTOP’tan tablete, Drone’dan oyun konsoluna; yüzlerce ödülden birini kazanma şansını elde edebilirsiniz. Belki ilk seferde olmasa da şansınızı sonraki yarışmalarda sürdürebilirsiniz.
          </Typography>
          <Typography variant="body1" align="center" paragraph>
            Haydi siz de Ev Okulu App Bilgi Yarışmasında yarışmacılar arasına katılın!
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}

export default Features;
