import React from "react";
import { useParams } from "react-router-dom";
import educationData from './Data';
import { FaQuestionCircle, FaExclamationTriangle, FaBook } from 'react-icons/fa';

const EducationDetail = () => {
  const { id } = useParams();
  const education = educationData.find((edu) => edu.id === parseInt(id));
  if (!education) {
    return <div className="text-center font-bold text-xl p-5">Eğitim bulunamadı.</div>;
  }

  return (
    <div className="max-w-4xl mx-auto shadow-lg rounded-lg overflow-hidden">
      <div style={{ fontFamily: "'Poppins', sans-serif" }} className="p-6">
        <h1 className="font-bold text-4xl mb-4">{education.title}</h1>
        <img
          src={education.image}
          className="w-full h-auto object-cover rounded-lg"
          style={{  maxHeight: '100vh', maxWidth: '100%' }}
        />
        <p className="text-lg animate-pulse bg-yellow-300 rounded-lg mt-2 p-4">990 TL yerine <span className="font-bold">Ev Okulu App</span> abonesi olanlar bu eğitime <span className="font-bold text-blue-600">ÜCRETSİZ</span> kaydolabilir ve sertifikasını alabilirler.</p>
        <div className="p-4">
          <h2 className="text-xl font-bold mb-2">{education.subtitle}</h2>
          <div className="border-t-2 border-gray-200 my-2"></div>
        </div>
        <div className="p-4">
          <h2 className="text-xl font-bold mb-2">{education.subtitle1}</h2>
          <p className="text-lg">{education.description1}</p>
        </div>
        <div className="p-4">
          <h2 className="text-xl flex  font-bold mb-2">
            <FaQuestionCircle style={{ color: 'blue', fontSize: '24px' }} className="mr-2" />
            <FaExclamationTriangle style={{ color: 'red', fontSize: '24px' }} className="mr-2" />{education.subtitle2}</h2>
          <div className="border-t-2 border-gray-200 my-2"></div>
          <p className="text-lg flex items-center gap-2">
            {education.description2}
          </p>
        </div>
        <div className="p-4">
          <h2 className="flex text-xl font-bold mb-2">
            <FaBook style={{ color: 'black', fontSize: '24px' }} className="mr-2" />
            {education.subtitle3}</h2>
          <div className="border-t-2 border-gray-200 my-2"></div>
          <p className="text-lg flex items-center gap-2">
            {education.description3}
          </p>
        </div>
      </div>
    </div>
  );
};

export default EducationDetail;
