import React from 'react';
import { Box, Container, Typography, CardMedia, List, ListItem, ListItemText } from '@mui/material';

const Features = () => {
  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} justifyContent="center" alignItems="center" pt={6}>
      <Box width={{ xs: '100%', md: '25%' }} p={3} order={{ xs: 2, md: 1 }}>
          <CardMedia
            component="img"
            image="/assets/inapp/profil.png"
            alt="App Screenshot"
            sx={{ mx: 'auto', maxWidth: '100%', height: 'auto' }}
          />
        </Box>
        <Box width={{ xs: '100%', md: '50%' }} p={3} order={{ xs: 1, md: 2 }}>
          <Typography variant="h4" component="h2" fontWeight="bold" color="primary" gutterBottom sx={{ textAlign: 'center', color: 'purple' }}>
            ‘‘Profil’’imde neler var?
          </Typography>
          <List sx={{ listStyleType: 'disc', pl: 4, mb: 4 }}>
            <ListItem sx={{ display: 'list-item', pl: 0 }}>
              <ListItemText primary="Tüm satın aldığınız içerik ve ürünleri görebileceğiniz ‘‘SATIN ALDIKLARIM’’ bölümü," />
            </ListItem>
            <ListItem sx={{ display: 'list-item', pl: 0 }}>
              <ListItemText primary="Tamamladığınız eğitimlerin sertifikalarını indirebileceğiniz veya görüntüleyebileceğiniz SERTİFİKALARIM bölümü," />
            </ListItem>
            <ListItem sx={{ display: 'list-item', pl: 0 }}>
              <ListItemText primary="İçeriklerden kazandığınız puanları görüntüleyebileceğiniz GELİŞİM PUANLARI bölümü," />
            </ListItem>
            <ListItem sx={{ display: 'list-item', pl: 0 }}>
              <ListItemText primary="Kayıt olduğunuz eğitimleri takip edebileceğiniz EĞİTİMLERİM bölümü," />
            </ListItem>
            <ListItem sx={{ display: 'list-item', pl: 0 }}>
              <ListItemText primary="Göndermiş olduğunuz dosyaları takip edebileceğiniz DOSYALARIM bölümü," />
            </ListItem>
            <ListItem sx={{ display: 'list-item', pl: 0 }}>
              <ListItemText primary="Profilinize uygun size tanımlanan fırsatları görebileceğiniz SİZE ÖZEL bölümü," />
            </ListItem>
            <ListItem sx={{ display: 'list-item', pl: 0 }}>
              <ListItemText primary="Kişisel bilgilerinizi girebileceğiniz BİLGİLERİM bölümü; profilinizi oluşturmaktadır." />
            </ListItem>
          </List>
        </Box>
      </Box>
    </Container>
  );
}

export default Features;
