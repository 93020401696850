import React from 'react';

const Homework = () => {
  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
      <div className="w-full max-w-lg p-6 bg-white rounded-lg shadow-md">
        <h2 className="text-3xl font-extrabold text-gray-800 mb-6 text-center">Hesap Silme Şartı</h2>
        <p className="mb-6 text-gray-600">
          Merhaba değerli <strong>Ev Okulu App </strong> kullanıcısı,
          <br />
          <br />
          Hesabınızı silmek için aşağıdaki bağlantıda yer alan formu doldurmanız yeterlidir. Burada uygulamaya kayıtlı olan e-posta adresinizi, telefon numaranızı, adınızı ve soyadınızı eklemeniz gerekmektedir.
          <br />
          <br />
          Belirtilen bilgileri kontrol ettikten ve hesabın size ait olduğunu doğruladıktan sonra hesabınızı sileceğiz.
          <br />
          <br />
          Hesabınız silindikten sonra kayıtlı olan e-posta adresinize işlemin sonucu hakkında bilgilendirme e-postası göndereceğiz.
        </p>
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSdvEnZKVeAhnRblGtbGJc6D-72ZTR0X9conKWDnBVoceawsEw/viewform?usp=sf_link"
          target="_blank"
          rel="noopener noreferrer"
          className="inline-block w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-lg transition-colors duration-200 ease-in-out text-center"
        >
          EV OKULU APP SİLME FORMU İÇİN TIKLAYINIZ
        </a>
      </div>
    </div>
  );
};

export default Homework;
