import React from 'react';
import { Box, Typography, Container } from '@mui/material';

const Features = () => {
  return (
    <Container maxWidth="md" sx={{ py: 8 }}>
      <Box textAlign="center" mb={6}>
        <Typography variant="h4" component="h2" fontWeight="bold" color="primary" gutterBottom>
          Vizyon
        </Typography>
        <Typography variant="body1" color="textSecondary">
          Türkiye’deki her bir evi eğitim ve mutluluk yuvasına dönüştürmektir. Ev eğitimi merkezidir.
        </Typography>
      </Box>
      <Box textAlign="center">
        <Typography variant="h4" component="h2" fontWeight="bold" color="primary" gutterBottom>
          Misyon
        </Typography>
        <Typography variant="body1" color="textSecondary">
          Ev Okulu Derneği, her türlü eğitim sürecinin ev ve aile ortamında desteklenmesi, ailelerin çocuklarının öğrenme süreçlerine katılması, okul ve ev uyumunun sağlanması, aile bağlarının kuvvetlenmesine yardımcı olmak ve ev okulu sistemlerinin ülkemizde tanınmasını amaçlamaktadır.
        </Typography>
      </Box>
    </Container>
  );
};

export default Features;
