import React from 'react';
import Atropos from 'atropos/react';

const head = `Eğitimin Süper App’i.`;
const subTitle = `Uygulamayı Ücretsiz İndirebilirsiniz!`;
const description = `Uygulama içerisinde;`;
const description2 = `Eğitimler, Eğitici Aktiviteler, Ev Oyunları, Sor, Yap, Araştır, Nerde Ne Var, Ödüllü Bilgi Yarışması, Sesli Blog, Video Blog, Makaleler, Kaynak Hazinesi, Günün Eğitim Reçetesi, Eğitimden Haberler, Duyurular bölümlerine erişebilirsiniz. Sadece abone olarak maddi bedeli 3 bin doları aşkın eğitimlere ÜCRETSİZ kaydolabilirsiniz. Üstelik sertifikası ve e – devlet tanımlamaları dahil ek hiçbir ücret ödemeden. Pek çok eğitime de özel ve indirimli koşullarla kayıt olma hakkına sahip olabilirsiniz.`;
const description3 = `EĞİTİMLER bölümünde çocukları da unutmadık. Çocuklar için Fransızca’dan İspanyolca’ya, Yunanca’dan Japonca’ya 8 farklı dilin eğitimi mevcut. Üstelik konuşma kulüpleri de sizi bekliyor. Çocuklar için Bilim Atölyesi – Yazarlık Atölyesi gibi pek çok geliştirici etkinlik Ev Okulu App uygulamasında. Bulunduğunuz şehirde hangi etkinliğe katılsam diye düşünüyor faydalı ve geliştirici etkinlikleri merak ediyor musunuz?`;
const description4 = ` Ev Okulu App NERDE NE VAR? Bölümü tam size göre. Şehrinizi filtreleyerek bulunduğunuz bölgede çocuklara ve yetişkinlere yönelik geliştirici ücretli ve ücretsiz etkinlikleri görebilir tüm detaylara erişebilirsiniz. Her hafta düzenlenecek olan Ödüllü BİLGİ YARIŞMALARI Ev Okulu App’te sizi bekliyor. Laptop, Tablet, Drone gibi değerli ödüllerin verileceği ve canlı gerçekleştirilecek Bilgi Yarışmalarında kendinizi sınayabilir ailecek keyifli vakit geçirebilirsiniz.`;
const description5 = ` Uygulamada başka ailelerin ve eğitimcilerin gerçekleştirdiği faydalı çalışmaları ev etkinliklerini görebilir, puanlayabilir ve ilham alabilirsiniz. Aynı şekilde sizler de çocuklarınız veya öğrencilerinizle gerçekleştirdiğiniz güzel etkinlikleri SİZDEN GELENLER bölümünde başka insanların istifadesine sunabilirsiniz.`;
const description6 = ` BLOG bölümümüzde uzun vakitler geçireceğinizi düşünüyoruz. Çünkü KAYNAK HAZİNESİ bölümünde yüzlerce ücretsiz kitap ve dergiye ulaşabileceksiniz. VİDEO BLOG bölümünde uzmanlarla gerçekleştirilmiş söyleşi ve röportajlara erişebilirsiniz. SESLİ BLOG bölümündeyse seyahatte veya araç kullanırken de dinleyebileceğiniz iyileştiren öykülere, sesli masal ve kitaplara, psikolojik terimlere, kişisel gelişim içeriklerine ulaşabilirsiniz.`;
const description7 = ` MAKALELERDE ise eğitim makalelerini okuyabilirsiniz. HABERLER ve DUYURULAR bölümünde eğitim alanındaki tüm gelişmeleri takip edebilirsiniz. TAKVİM bölümünde uygulamada sunduğumuz etkinliklerin tamamını takip edebilir, yaklaşan etkinlikleri görebilirsiniz. GÜNÜN REÇETESİ sizin iyi hissetmenizi sağlayacak ve günlük kişisel gelişiminize katkı sunacak içeriklere sahip. Üstelik her gün paylaşılan günün sözü, deyimi, atasözü, videosu, animasyonu gibi içerikleri sevdiklerinizle ve sosyal medya hesabınızda paylaşabilirsiniz.`;

const HomeContent = () => {
  return (
    <>

          <h1 style={{ fontFamily: "'Poppins', sans-serif" }} className="text-4xl pt-8 text-center font-bold text-purple-600 font-sans"> {head} </h1>
      <div className="flex flex-col md:flex-row items-center justify-center p-8 ">
        <div className="md:w-1/6 ">
        <Atropos className="my-atropos">

          
          <img src="/google1.png" alt="Resim 1" className="w-full h-auto md:max-h-full" style={{ maxWidth: '100%', height: 'auto' }} />
          </Atropos>

        </div>
        <div  className="md:w-1/2 p-8 text-center md:text-left">
          <p style={{ fontFamily: "'Poppins', sans-serif" }} className="text-black mt-4 font-sans"><b> {subTitle}</b><br/>  <br/> {description2} </p>
        </div>
      </div>  
      <div className="flex flex-col md:flex-row items-center justify-center p-8 bg-[#F5FAFD]">
        <div className="md:w-1/2 p-8 text-center md:text-left">
          <p style={{ fontFamily: "'Poppins', sans-serif" }}  className="text-black mt-4 font-sans"> {description3} </p>
        </div>
        <div className="md:w-1/6">
        <Atropos className="my-atropos">

          <img src="/google2.png" alt="Resim 2" className="w-full h-auto md:max-h-full" style={{ maxWidth: '100%', height: 'auto' }} />
          </Atropos>

        </div>
      </div>    
      <div className="flex flex-col md:flex-row items-center justify-center p-8  ">
        <div className="md:w-1/6">
        <Atropos className="my-atropos">

          <img src="/google3.png" alt="Resim 3" className="w-full h-auto md:max-h-full" style={{ maxWidth: '100%', height: 'auto' }} />
          </Atropos>

        </div>
        <div className="md:w-1/2 p-8 text-center md:text-left">
          <p style={{ fontFamily: "'Poppins', sans-serif" }}  className="text-black mt-4 font-sans"> {description4} </p>
        </div>
      </div>  
      <div className="flex flex-col md:flex-row items-center justify-center p-8  bg-[#F5FAFD]">
        <div className="md:w-1/2 p-8 text-center md:text-left">
          <p style={{ fontFamily: "'Poppins', sans-serif" }} className="text-black mt-4 font-sans"> {description5} </p>
        </div>
        <div className="md:w-1/6">
        <Atropos className="my-atropos">

          <img src="/google4.png" alt="Resim 4" className="w-full h-auto md:max-h-full" style={{ maxWidth: '100%', height: 'auto' }} />
          </Atropos>

        </div>
      </div>  
      <div className="flex flex-col md:flex-row items-center justify-center p-8  ">
        <div className="md:w-1/6">

          <img src="/google.png" alt="Resim 5" className="w-full h-auto md:max-h-full" style={{ maxWidth: '100%', height: 'auto' }} />

        </div>
        <div className="md:w-1/2 p-8 text-center md:text-left">
          <p style={{ fontFamily: "'Poppins', sans-serif" }} className="text-black mt-4 font-sans"> {description6} </p>
        </div>
      </div>  
      <div className="flex flex-col md:flex-row items-center justify-center p-8   bg-[#F5FAFD]">
        <div className="md:w-1/2 p-8 text-center md:text-left">
          <p style={{ fontFamily: "'Poppins', sans-serif" }} className="text-black mt-4 font-sans"> {description7} </p>
        </div>
        <div className="md:w-1/6">
          <img src="/app.png" alt="Resim 6" className="w-full h-auto md:max-h-full" style={{ maxWidth: '100%', height: 'auto' }} />

        </div>
      </div>  

    </>
  );
};

export default HomeContent;
