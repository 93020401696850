import React from 'react';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="font-sans">
      <div className="container px-6 mx-auto">
        <hr className="my-6 border-gray-200 md:my-8 dark:border-gray-700 h-2" />
        
        <div className="sm:flex sm:items-center sm:justify-between">
          <div className="flex flex-1 gap-4 hover:cursor-pointer">
            <a href="https://play.google.com/store/apps/details?id=org.evokulu.app&pcampaignid=web_share">
              <img src="https://www.svgrepo.com/show/303139/google-play-badge-logo.svg" width="130" height="110" alt="" />
            </a>
            <a href="https://apps.apple.com/tr/app/ev-okulu-app/id6478512351?l=tr">
              <img src="https://www.svgrepo.com/show/303128/download-on-the-app-store-apple-logo.svg" width="130" height="110" alt="" />
            </a>
          </div>
            
          <div className="flex gap-4 hover:cursor-pointer">
            <a href="https://www.facebook.com/evokuludernegi/">
              <FaFacebook size={30} />
            </a>
            <a href="https://twitter.com/evokuludernegi/">
              <FaTwitter size={30} />
            </a>
            <a href="https://www.instagram.com/evokuludernegi/">
              <FaInstagram size={30} />
            </a>
            <a href="https://www.linkedin.com/company/evokuludernegi/">
              <FaLinkedin size={30} />
            </a>
          </div>
        </div>
              </div>
    </footer>
  );
};

export default Footer;
