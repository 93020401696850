import React from 'react';
import { Box, Container, Typography, CardMedia } from '@mui/material';

const Features = () => {
  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} justifyContent="center" alignItems="center">
      <Box width={{ xs: '100%', md: '25%' }} p={3} order={{ xs: 2, md: 1 }}>
          <CardMedia
            component="img"
            image="/assets/inapp/takvim.png"
            alt="App Screenshot"
            sx={{ mx: 'auto', maxWidth: '100%', height: 'auto' }}
          />
        </Box>
        <Box width={{ xs: '100%', md: '50%' }} p={3} order={{ xs: 1, md: 2 }}>
          <Typography variant="h4" component="h2" fontWeight="bold" color="primary" gutterBottom sx={{ textAlign: 'center', color: 'purple' }}>
            Ev Okulu App ‘‘Takvim’’de ne var?
          </Typography>
          <Typography variant="body1" align="center" paragraph>
            ‘‘Hangi gün, nerede, ne gibi etkinlikler var?’’ merak ediyorsanız tüm müze, atölye, tiyatro, seminer içerikleri; hepsi tek uygulamada elinizin altında.
          </Typography>
          <Typography variant="body1" align="center" paragraph>
            Takvim bölümünde günü seçerek o gün nerede, hangi lokasyonda, ne tür etkinlikler var; görebileceğiniz bilgileri Ev Okulu App ‘‘Takvim’’ bölümünde görebilirsiniz.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}

export default Features;
