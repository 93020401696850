import React, { useState, useRef } from "react";
import { BiMinus } from "react-icons/bi";
import { BsPlusLg } from "react-icons/bs";
import { Box, Typography, IconButton, Collapse } from '@mui/material';

export default function SingleQuestion({ question, answer, embeddedVideo }) {
  const [activeIndex, setActiveIndex] = useState(null);
  const accordionRef = useRef(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const handleClickOutside = (event) => {
    if (accordionRef.current && !accordionRef.current.contains(event.target)) {
      setActiveIndex(null);
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Box ref={accordionRef} sx={{ border: '1px solid #ccc', borderRadius: 2, mb: 2, backgroundColor: '#fff', overflow: 'hidden' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" p={2} onClick={() => toggleAccordion(question)}>
        <Typography variant="h6" component="h2" sx={{ fontFamily: "'Poppins', sans-serif", color: '#333', cursor: 'pointer' }}>
          {question}
        </Typography>
        <IconButton>
          {activeIndex !== question ? <BsPlusLg style={{ color: '#555' }} /> : <BiMinus style={{ color: '#555' }} />}
        </IconButton>
      </Box>
      <Collapse in={activeIndex === question}>
        <Box sx={{ borderTop: '1px solid #ccc', p: 2, backgroundColor: '#f9f9f9' }}>
          {embeddedVideo && (
            <Box sx={{ mb: 2, boxShadow: 1, borderRadius: 1, overflow: 'hidden', position: 'relative', pt: '56.25%' }}>
              <iframe
                width="100%"
                height="100%"
                src={embeddedVideo}
                title="Embedded Video"
                frameBorder="0"
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                allowFullScreen
              ></iframe>
            </Box>
          )}
          <Typography variant="body2" sx={{ fontFamily: "'Poppins', sans-serif" }}>
            {answer}
          </Typography>
        </Box>
      </Collapse>
    </Box>
  );
}
