import React from 'react';
import { Box, Container, Typography, CardMedia } from '@mui/material';

const Features = () => {
  return (
    <Container maxWidth="lg" sx={{ py: 8, backgroundColor: '#F5FAFD' }}>
      <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} justifyContent="center" alignItems="center">
      <Box width={{ xs: '100%', md: '25%' }} p={3} order={{ xs: 2, md: 1 }}>
          <CardMedia
            component="img"
            image="/assets/inapp/egitimler.png"
            alt="App Screenshot"
            sx={{ mx: 'auto', maxWidth: '100%', height: 'auto' }}
          />
        </Box>
        <Box width={{ xs: '100%', md: '50%' }} p={3} order={{ xs: 1, md: 2 }}>
          <Typography variant="h4" component="h2" fontWeight="bold" color="primary" gutterBottom sx={{ textAlign: 'center', color: 'purple' }}>
            Ev Okulu App ‘‘Eğitimler’’de ne var?
          </Typography>
          <Typography variant="body1" align="center" paragraph>
            Eğitim programlarıyla kendinizi geliştirmek ve geçerli sertifika programlarıyla hem mesleki gelişiminize hem de ebeveynlik becerilerinize katkı sunmak mı istiyorsunuz? Sertifikalı eğitim programlarına katılmak istiyor ama bütçe ayırmakta zorlanıyor musunuz? Ev Okulu App ‘‘EĞİTİMLER’’ bölümü tam size göre.
          </Typography>
          <Typography variant="body1" align="center" paragraph>
            Ev Okulu App ile mali bedeli 100 bin TL’yi aşkın sertifikalı eğitimlere ÜCRETSİZ kaydolabilirsiniz. Yüksek maliyetli pek çok eğitim programlarına ise düşük maliyetlerle katılabilirsiniz. Eğitimlerinizi tamamlayıp, sınavlarınıza online olarak katılabilir ve pek çoğu e-devlet onaylı eğitim programlarından sertifikalarınızı elde edebilirsiniz.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}

export default Features;
